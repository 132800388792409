import React, {
	useEffect,
	useState
} from "react";
import {
	Box,
	Grid,
	Typography,
	Paper,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import SellerDetails from "./views/SellerDetails/SellerDetails";
import WarehouseMappings from "./views/WarehouseMappings/WarehouseMappings";
import FinanceDetails from "./views/FinanceDetails/FinanceDetails";
import { capitalizeFirstLetter } from "../../utils/text";
import { BreadCrumb } from "../Shared/BreadCrumb/BreadCrumb";

export default function SellerCreate() {
	const location = useLocation();
	const existingSeller = location.state?.existingSeller;
	const sellerOperation = location.state?.sellerOperation;

	const [state, setState] = useState({
		sellerDetailsForm: {},
		warehouseMappings: {},
		title: "Create",
		panelsVisible: {
			isSellerDetailsPanelVisible: true,
			isFinanceDetailsPanelVisible: false,
			isWarehouseMappingsPanelVisible: false,
		},
		sellerError: {
			sellerID: 0,
			errorMessage: "",
		},
	});

	useEffect(() => {
		if (!sellerOperation) return;

		setState(previousState => {
			return {
				...previousState,
				title: capitalizeFirstLetter(sellerOperation),
			};
		});

	}, [sellerOperation]);

	const onSellerDetailsFormSubmit = (props) => {
		setState(previousState => {
			return {
				...previousState,
				sellerDetailsForm: props,
			};
		});
	};

	const onChangeWarehouseMappings = (props) => {
		setState(previousState => {
			return {
				...previousState,
				warehouseMappings: props,
			};
		});
	};

	const onPanelsVisibleChange = (props) => {
		setState(previousState => {
			return {
				...previousState,
				panelsVisible: props,
			};
		});
	};

	const PageHeading = () => {
		return (
			<Grid item xs={12}>
				<Grid item xs={12}>
					<Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
						{state.title} Seller
					</Typography>
					<Grid item xs />
				</Grid>
			</Grid>
		);
	};

	return (
		<Box id="seller-create">
			<BreadCrumb 
				breadcrumbs={[
					{ label: "Seller Configuration", url: "/seller-configuration" },
					{ label: `${state?.title} Seller` }
				]}
			/>
			<PageHeading />
			<Box>
				<Grid container spacing={1}>
					<Box
						className={`panel${state.panelsVisible.isSellerDetailsPanelVisible === true ? "-active" : "-disabled"}`}>
						<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
							<Typography component="h3" variant="h6">1. Add Seller Details</Typography>
							<Box
								className={`panel${state.panelsVisible.isSellerDetailsPanelVisible === true ? "-show" : "-hide"}`}>
								<SellerDetails
									onSellerDetailsFormSubmit={onSellerDetailsFormSubmit}
									onPanelsVisibleChange={onPanelsVisibleChange}
									sellerError={state.sellerError}
									existingSeller={existingSeller}
									sellerOperation={sellerOperation}
								/>
							</Box>
						</Paper>
					</Box>
					<Box
						className={`panel${state.panelsVisible.isWarehouseMappingsPanelVisible === true ? "-active" : "-disabled"}`}>
						<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
							<Typography component="h3" variant="h6">2. Add Warehouse Mappings</Typography>
							<Box
								className={`panel${state.panelsVisible.isWarehouseMappingsPanelVisible === true ? "-show" : "-hide"}`}>
								<WarehouseMappings
									onChangeWarehouseMappings={onChangeWarehouseMappings}
									onPanelsVisibleChange={onPanelsVisibleChange}
									sellerError={state.sellerError}
									existingSeller={existingSeller}
									sellerOperation={sellerOperation}
								/>
							</Box>
						</Paper>
					</Box>
					<Box
						className={`panel${state.panelsVisible.isFinanceDetailsPanelVisible === true ? "-active" : "-disabled"}`}>
						<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
							<Typography component="h3" variant="h6">3. Add Finance Details</Typography>
							<Box
								className={`panel${state.panelsVisible.isFinanceDetailsPanelVisible === true ? "-show" : "-hide"}`}>
								<FinanceDetails
									sellerDetailsForm={state.sellerDetailsForm}
									warehouseMappingsForm={state.warehouseMappings}
									onPanelsVisibleChange={onPanelsVisibleChange}
									existingSeller={existingSeller}
									sellerOperation={sellerOperation}
								/>
							</Box>
						</Paper>
					</Box>
				</Grid>
			</Box>
		</Box>
	);
}