import React, { useState } from "react";
import SellerDropDown from "../Shared/SellerDropDown/SellerDropDown";
import DateRange from "../Shared/DateRange/DateRange";
import DataTablePaginated from "../Shared/Table/TablePaginated";
import {
	getFormattedStartTime,
	getFormattedEndTime,
	getCurrentDateTruncated
} from "../../utils/date.js";
import { CheckIfSellerSelected } from "../../utils/seller";
import {
	Box,
	Grid,
	Typography,
	Paper,
	FormControlLabel,
	Switch,
} from "@mui/material";
import * as tableConstants from "../Shared/Config/TableConfig";
import { DownloadButton } from "../Shared/Buttons/Buttons";
import { clickElementByID } from "../../utils/onClick"; 
import { getSellerDetails } from "../../utils/getSellerDetails";

export default function FinanceShippingFulfilment() {
	const pageRowOptions = tableConstants.PAGE_ROW_OPTIONS;
	const currentDate = getCurrentDateTruncated();

	const [state, setState] = useState({
		selectedSeller: "",
		selectedSellerID: 0,
		startDate: getFormattedStartTime(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)),
		endDate: getFormattedEndTime(new Date(currentDate.getFullYear(), currentDate.getMonth(), -1)),
		currentPageFulfilment: 1,
		currentPageShipping: 1,
		currentPageStorage: 1,
		showNonChargeableSkus: false,
		rowsPerPageFulfilment: pageRowOptions[3],
		rowsPerPageShipping: pageRowOptions[3],
		rowsPerPageStorage: pageRowOptions[3],
		csvFileName: "",
	});

	function onSellerSelect(seller) {
		setState(previousState => {
			return {
				...previousState,
				selectedSeller: seller,
				selectedSellerID: getSellerDetails(seller, "id"),
				csvFileName: `${getSellerDetails(seller, "code")}_Finance_`,
				currentPageFulfilment: 1,
				currentPageShipping: 1,
				currentPageStorage: 1,
			};
		});
	}

	function onStartDateChange(start) {
		setState(previousState => {
			return {
				...previousState,
				startDate: start,
				currentPageFulfilment: 1,
				currentPageShipping: 1,
				currentPageStorage: 1,
			};
		});
	}

	function onEndDateChange(end) {
		setState(previousState => {
			return {
				...previousState,
				endDate: end,
				currentPageFulfilment: 1,
				currentPageShipping: 1,
				currentPageStorage: 1,
			};
		});
	}

	const onChangePageRowFulfilment = (pageRows) => {
		setState(previousState => {
			return {
				...previousState,
				rowsPerPageFulfilment: pageRows,
				currentPageFulfilment: 1,
			};
		});
	};

	const onChangePageRowShipping = (pageRows) => {
		setState(previousState => {
			return {
				...previousState,
				rowsPerPageShipping: pageRows,
				currentPageShipping: 1,
			};
		});
	};

	const onChangePageRowStorage = (pageRows) => {
		setState(previousState => {
			return {
				...previousState,
				rowsPerPageStorage: pageRows,
				currentPageStorage: 1,
			};
		});
	};

	const params = new URLSearchParams({
		seller: state.selectedSellerID,
		start_date: getFormattedStartTime(state.startDate),
		end_date: getFormattedEndTime(state.endDate),
	});

	const storageParams = new URLSearchParams(params);
	storageParams.delete("seller");
	storageParams.append("sellers", state.selectedSellerID);
	storageParams.append("show_all_stock", true);
	storageParams.append("show_non_chargeable_skus", state.showNonChargeableSkus);

	const fulfilmentURL = "/v1/invoicing/fulfillment?";
	const shippingURL = "/v1/invoicing/shipping?";
	const storageURL = "/v1/invoicing/storage?";

	const FulfilmentHeader = [
		"client",
		"warehouse",
		"order_number",
		"despatch_date",
		"skus_in_order",
		"number_of_picks",
		"number_of_additional_picks",
		"first_pick_charged",
		"additional_picks_charged",
		"total_picks_charged",
		"total_per_item_packaging",
		"packaging_per_order",
		"packaging_total",
		"pricing_error"
	];

	const shippingHeader = [
		"seller",
		"warehouse",
		"order_number",
		"created_date",
		"despatch_date",
		"origin",
		"destination",
		"courier_service",
		"courier_code",
		"total_weight",
		"number_of_packages",
		"surcharge_cost",
		"price_ex_vat",
		"total_shipping_cost",
		"pricing_error",
	];

	const storageHeader = [
		"seller",
		"sku",
		"warehouse",
		"date",
		"on_hand",
		"stock",
		"height",
		"width",
		"depth",
		"weight",
		"total_volume_m3",
		"price_per_m3_per_day",
		"total_price_per_day",
		"pricing_error"
	];

	const isDisabled = !state.selectedSellerID ? true : false;

	const FulfilmentTable = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
				<Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Fulfilment
				</Typography>

				<DataTablePaginated
					headers={FulfilmentHeader}
					dateHeaders={["despatch_date"]}
					seller={state.selectedSellerID}
					url={fulfilmentURL}
					params={params}
					currentPage={state.currentPageFulfilment}
					rowsPerPage={state.rowsPerPageFulfilment}
					onChangePageRow={onChangePageRowFulfilment}
					csvFilename={`${state.csvFileName}Fulfilment_csv`}
					onClick={() => clickElementByID()}
				/>

			</Paper>
		);
	};

	const ShippingTable = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
				<Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Shipping
				</Typography>

				<DataTablePaginated
					headers={shippingHeader}
					dateHeaders={["created_date", "despatch_date"]}
					seller={state.selectedSellerID}
					url={shippingURL}
					params={params}
					currentPage={state.currentPageShipping}
					rowsPerPage={state.rowsPerPageShipping}
					onChangePageRow={onChangePageRowShipping}
					csvFilename={`${state.csvFileName}Shipping_csv`}
					onClick={() => clickElementByID()}
				/>

			</Paper>
		);
	};

	const StorageTable = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>

				<Grid item xs={4} sm={6} lg={12} xl={12} container mb={0}>

					<Typography variant="h6" sx={{ marginBottom: 1 }}>
						Storage
					</Typography>
					<Grid item xs />

					<Grid item>
						<FormControlLabel control={
							<Switch
								checked={state.showNonChargeableSkus}
								onChange={(e) => {
									setState(previousState => {
										return {
											...previousState,
											showNonChargeableSkus: e.target.checked
										};
									});
								}}
							/>
						} label="Show Non-Chargeable Skus?" labelPlacement="start" />
					</Grid>

				</Grid>

				<DataTablePaginated
					headers={storageHeader}
					dateHeaders={["date"]}
					seller={state.selectedSellerID}
					url={storageURL}
					params={storageParams}
					currentPage={state.currentPageStorage}
					rowsPerPage={state.rowsPerPageStorage}
					onChangePageRow={onChangePageRowStorage}
					csvFilename={`${state.csvFileName}Storage_csv`}
					onClick={() => clickElementByID()}
				/>

			</Paper>
		);
	};

	const PageHeading = () => {
		return (
			<Grid item xs={3} sm={4} lg={10} xl={12} container mb={3}>
				<Grid item xs="auto" mr={1} mb={2}>
					<Typography variant="h5">
                        Finance - Shipping &amp; Fulfilment
					</Typography>
				</Grid>
				<Grid item xs />
				<Grid item>
					<Grid item xs="auto">
						<DownloadButton
							icon={true}
							text={"Fulfilment"}
							onClick={() => clickElementByID(`${state.csvFileName}Fulfilment_csv`)}
							disabled={isDisabled}
							variant="contained"
							sx={{mr: 2, mb: 2}}
						/>
						<DownloadButton
							icon={true}
							text={"Shipping"}
							onClick={() => clickElementByID(`${state.csvFileName}Shipping_csv`)}
							disabled={isDisabled}
							variant="contained"
							sx={{mr: 2, mb: 2}}
						/>
						<DownloadButton
							icon={true}
							text={"Storage"}
							onClick={() => clickElementByID(`${state.csvFileName}Storage_csv`)}
							disabled={isDisabled}
							variant="contained"
							sx={{mr: 2, mb: 2}}
						/>						
						<SellerDropDown
							onChangeSelect={onSellerSelect}
							selected={state.selectedSeller}
						/>
						<DateRange
							startDate={state.startDate}
							endDate={state.endDate}
							onStartDateChange={onStartDateChange}
							onEndDateChange={onEndDateChange}
						/>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	return (
		<Box id="finance-shipping-fulfilment">
			<PageHeading />
			{(!state.selectedSellerID) ?
				<CheckIfSellerSelected />
				:
				<>
					<FulfilmentTable />
					<ShippingTable />
					<StorageTable />
				</>
			}
		</Box>
	);
}