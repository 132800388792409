import React, {
	useState,
	useContext,
	useEffect,
	useMemo
} from "react";
import {
	Box,
	Grid,
	Typography,
	Paper,
	Stack,
} from "@mui/material";
import SellerDropDown from "../Shared/SellerDropDown/SellerDropDown";
import { CheckIfSellerSelected } from "../../utils/seller";
import { getSellerDetails } from "../../utils/getSellerDetails";
import { AntSwitch } from "../../utils/antSwitch";
import { DataContext } from "../../App";
import DataTable from "../Shared/Table/Table";
import Modal from "../Shared/Modal/Modal";
import { capitalizeFirstLetter } from "../../utils/text";

export default function FinanceSellerStatus() {

	const context = useContext(DataContext);
	const [state, setState] = useState({
		selectedSeller: "",
		selectedSellerID: 0,
		sellerStatusHistory: [],
		sellerStatusPrevious: "",
		isSellerActive: true,
		isModalOpen: false,
		modalBodyTitle: "",
		modalBodyMessage1: "",
		modalBodyMessage1Data: [],
		modalBodyMessage2: "",
		modalButtonVariant1: "",
		modalButtonText1: "",
		modalButtonActionType1: "",
		modalButtonAction1: "",
		modalButtonVariant2: "",
		modalButtonText2: "",
		modalButtonActionType2: "",
		modalButtonAction2: "",
		modalButtonColor2: "primary",
	});

	function onSellerSelect(seller) {
		setState(previousState => {
			return {
				...previousState,
				selectedSeller: seller,
				selectedSellerID: getSellerDetails(seller, "id"),
				isSellerActive: true,
			};
		});
	}

	const updateSellerStatus = async (status) => {

		if (!status || !state.selectedSellerID) return;

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: false,
				modalButtonText2: "",
			};
		});

		try {
			await context.dataProvider.updateSellerStatus(state.selectedSellerID, status);
			setState(previousState => {
				return {
					...previousState,
					modalBodyTitle: "Seller Account Status Updated",
					modalBodyMessage1: `The seller account status has been successfully updated to "${status}".`,
					modalBodyMessage2: "",
					modalButtonVariant1: "contained",
					modalButtonText1: "Close",
					modalButtonActionType1: "close",
					modalButtonAction1: "",
					isSellerActive: !state.isSellerActive
				};
			});
			getSellerStatusHistory();
		} catch (e) {
			setState(previousState => {
				return {
					...previousState,
					modalBodyTitle: "Seller Account Status Update Failed",
					modalBodyMessage1: "Something went wrong.",
					modalBodyMessage2: "Please try again, if this problem continues then contact support@bezos.ai",
					modalButtonVariant1: "contained",
					modalButtonText1: "Close",
					modalButtonActionType1: "close",
					modalButtonAction1: "",
				};
			});
		}

		setState(previousState => {
			return {
				...previousState,
				isModalOpen: true,
			};
		});

	};

	const onChangeSellerStatus = (e) => {

		const statusChange = () => {
			if (e.target.checked === true) return capitalizeFirstLetter(state.sellerStatusPrevious);
			return "Frozen";
		};

		setState(previousState => {
			return {
				...previousState,
				modalBodyTitle: "Change Seller Account Status",
				modalBodyMessage1: `Please confirm you would like to change the Seller account status to "${statusChange()}" ?`,
				modalBodyMessage2: "",
				modalButtonVariant1: "outlined",
				modalButtonText1: "No",
				modalButtonActionType1: "close",
				modalButtonAction1: "",
				modalBodyMessage1Data: [],
				modalButtonVariant2: "contained",
				modalButtonText2: "Yes",
				modalButtonActionType2: "function",
				modalButtonAction2: () => updateSellerStatus(statusChange()),
				modalButtonColor2: "primary",
				isModalOpen: true,
			};
		});
	};

	const SellerDetails = () => {		
		return (
			<Typography variant="h6">
				{getSellerDetails(state.selectedSeller, "code")} - {getSellerDetails(state.selectedSeller, "name")}
			</Typography>
		);
	};

	const SellerToggle = () => {
		return (
			<>
				<Box mt={2}>

					<Grid item container className="align-content-vertical">

						<Grid item mr={2}>
							<Typography>Seller Account Status</Typography>
						</Grid>

						<Grid item>
							<Stack direction="row" spacing={0} alignItems="center">
								<Typography>Off</Typography>
								<AntSwitch 
									inputProps={{ "aria-label": "ant design" }}
									checked={state.isSellerActive}
									onChange={(e) => onChangeSellerStatus(e)}
								/>
								<Typography>On</Typography>
							</Stack>
						</Grid>

					</Grid>

				</Box>
			</>
		);
	};
	
	const params = useMemo(() => new URLSearchParams({
		seller_id: state.selectedSellerID,
	}), [state.selectedSeller]);

	const getSellerStatuses = async (status) => {

		if (!status) return;

		const currentStatus = status.current_status.toLowerCase();
		const previousStatus = status.previous_status.toLowerCase();

		setState(previousState => {
			return {
				...previousState,
				sellerStatusPrevious: previousStatus,
				isSellerActive: currentStatus !== "frozen" ? true : false
			};
		});

	};

	const getSellerStatusHistory = async () => {

		if (!state.selectedSellerID) return;

		try {
			const response = await context.dataProvider.getSellerStatusHistory(state.selectedSellerID, params);
			setState(previousState => ({
				...previousState,
				sellerStatusHistory: response.data,
			}));
			getSellerStatuses(response.data[0]);
		} catch (e) {
			setState(previousState => ({
				...previousState,
				sellerStatusHistory: [],
			}));
		}
		
	};

	useEffect(() => {
		getSellerStatusHistory();
	}, [state.selectedSeller]);

	const tableHeaders = [
		{
			key: "seller_id",
			label: "Seller ID",
		},
		{
			key: "current_status",
			label: "Current Status",
		},
		{
			key: "last_updated",
			label: "Last Updated",
		},
		{
			key: "last_updated_by",
			label: "Last Updated By",
		},
		{
			key: "previous_status",
			label: "Previous Status",
		},
	];

	const onModalClose = (props) => {
		setState(previousState => {
			return {
				...previousState,
				isModalOpen: props,
			};
		});
	};

	const PageHeading = () => {
		return (
			<Grid item xs={3} sm={4} lg={10} xl={12} container mb={3}>
				<Grid item xs="auto" mr={1} mb={2}>
					<Typography variant="h5">
                        Finance - Seller Status
					</Typography>
				</Grid>
				<Grid item xs />
				<Grid item>
					<Grid item xs="auto">
						<SellerDropDown
							onChangeSelect={onSellerSelect}
							selected={state.selectedSeller}
						/>
					</Grid>
				</Grid>                
			</Grid>
		);
	};

	const PageBody = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
				<SellerDetails />
				<SellerToggle />
				<Typography variant="h6" mt={2} mb={1}>History</Typography>
				<DataTable
					data={state.sellerStatusHistory.sort((a, b) => a.last_updated > b.last_updated ? -1 : 1)}
					headers={tableHeaders}
					dateHeaders={["last_updated"]}
					isLoading={false}
					usePagination={false}
				/>
			</Paper>
		);
	};

	return (
		<Box id="finance-seller-status">
			<PageHeading />
			{(!state.selectedSellerID) ?
				<CheckIfSellerSelected />
				:
				<>
					<PageBody />
					<Modal
						onModalClose={onModalClose}
						isModalOpen={state.isModalOpen}
						modalBodyTitle={state.modalBodyTitle}
						modalBodyMessage1={state.modalBodyMessage1}
						modalBodyMessage1Data={state.modalBodyMessage1Data}
						modalBodyMessage2={state.modalBodyMessage2}
						modalButtonVariant1={state.modalButtonVariant1}
						modalButtonText1={state.modalButtonText1}
						modalButtonActionType1={state.modalButtonActionType1}
						modalButtonAction1={state.modalButtonAction1}
						modalButtonVariant2={state.modalButtonVariant2}
						modalButtonText2={state.modalButtonText2}
						modalButtonActionType2={state.modalButtonActionType2}
						modalButtonAction2={state.modalButtonAction2}
						modalButtonColor2={state.modalButtonColor2}
					/>
				</>
			}
		</Box>
	);
}