import React from "react";
import * as Yup from "yup";
import {
	Form,
	Formik
} from "formik";
import { TextfieldFormik } from "../../Shared/TextField/TextField";
import DropDown from "../../Shared/FormikDropDown/DropDown";
import {
	Box,
	Button
} from "@mui/material";

export function AdditionalOptions({
	onAdditionalOptionsFormSubmit,
	onPanelsVisibleChange,
	existingWarehouse
}) {

	const INITIAL_FORM_STATE = {
		is_live: existingWarehouse.is_live ?? false,
		is_backward_sync_enabled: existingWarehouse.is_backward_sync_enabled ?? false,
		uses_default_asn_id: existingWarehouse.uses_default_asn_id ?? false,
		supports_return_summary: existingWarehouse.supports_return_summary ?? false,
		has_event_based_despatch_sync: existingWarehouse.has_event_based_despatch_sync ?? false,
		return_label_available: existingWarehouse.return_label_available ?? false,
		requires_contact_details_for_international_orders: existingWarehouse.requires_contact_details_for_international_orders ?? false,
		asn_cancellation_method: existingWarehouse.asn_cancellation_method ?? "",
		always_use_default_supplier: existingWarehouse.always_use_default_supplier ?? false,
		has_collection_scan_delay: existingWarehouse.has_collection_scan_delay ?? false,
		requires_tracking_number_for_asn: existingWarehouse.requires_tracking_number_for_asn ?? false,
		send_non_despatched_order_emails: existingWarehouse.send_non_despatched_order_emails ?? false,
		requires_contact_details_for_domestic_orders: existingWarehouse.requires_contact_details_for_domestic_orders ?? false,
		sendcloud_brand_domain: existingWarehouse.sendcloud_brand_domain ?? "",
		sendcloud_sender_address_id: existingWarehouse.sendcloud_sender_address_id ?? 0,
	};

	const FORM_VALIDATION = Yup.object().shape({
		is_live: Yup.string(),
		is_backward_sync_enabled: Yup.string(),
		uses_default_asn_id: Yup.string(),
		supports_return_summary: Yup.string(),
		has_event_based_despatch_sync: Yup.string(),
		return_label_available: Yup.string(),
		requires_contact_details_for_international_orders: Yup.string(),
		asn_cancellation_method: Yup.string(),
		always_use_default_supplier: Yup.string(),
		has_collection_scan_delay: Yup.string(),
		requires_tracking_number_for_asn: Yup.string(),
		send_non_despatched_order_emails: Yup.string(),
		requires_contact_details_for_domestic_orders: Yup.string(),
		sendcloud_brand_domain: Yup.string(),
		sendcloud_sender_address_id: Yup.number(),
	});

	const BooleanDropDown = ({ id, label, value }) => {
		const booleanData = [
			{ key: "yes", value: true, name: "Yes" },
			{ key: "no", value: false, name: "No" }
		];

		return (
			<DropDown
				id={id}
				name={id}
				label={label}
				value={value}
				data={booleanData}
				required={true}
				sx={{ width: "100%", mt: 2, mb: 1 }}
				size="small"
			/>
		);
	};

	const handleBackButton = () => {
		onPanelsVisibleChange({
			isSummaryDetailsPanelVisible: true,
			isAdditionalOptionsPanelVisible: false,
			isContactDetailsPanelVisible: false,
		});
	};

	const onSubmitForm = (values) => {
		onAdditionalOptionsFormSubmit(values);
		onPanelsVisibleChange({
			isSummaryDetailsPanelVisible: false,
			isAdditionalOptionsPanelVisible: false,
			isContactDetailsPanelVisible: true,
		});
	};

	return (
		<>
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE
				}}
				enableReinitialize // disables validation on page load
				validateOnChange={true}
				validateOnBlur={true}
				validationSchema={FORM_VALIDATION}
				onSubmit={(values) => onSubmitForm(values)}
			>
				{({ values }) => (
					<Form
						noValidate
						autoComplete="off"
					>

						<BooleanDropDown
							id="is_live"
							label="Is Warehouse Live"
							value={values.is_live}
						/>

						<BooleanDropDown
							id="is_backward_sync_enabled"
							label="Is Backward Sync Enabled"
							value={values.is_backward_sync_enabled}
						/>

						<BooleanDropDown
							id="uses_default_asn_id"
							label="Uses Default ASN ID"
							value={values.uses_default_asn_id}
						/>

						<BooleanDropDown
							id="supports_return_summary"
							label="Supports Return Summary"
							value={values.supports_return_summary}
						/>

						<BooleanDropDown
							id="has_event_based_despatch_sync"
							label="Has Event Based Despatch Sync"
							value={values.has_event_based_despatch_sync}
						/>

						<BooleanDropDown
							id="return_label_available"
							label="Return Label Available"
							value={values.return_label_available}
						/>

						<BooleanDropDown
							id="requires_contact_details_for_international_orders"
							label="Requires Contact Details For International Orders"
							value={values.requires_contact_details_for_international_orders}
						/>

						<TextfieldFormik
							name="asn_cancellation_method"
							label="ASN Cancellation Method"
						/>

						<BooleanDropDown
							id="always_use_default_supplier"
							label="Always Use Default Supplier"
							value={values.always_use_default_supplier}
						/>

						<BooleanDropDown
							id="has_collection_scan_delay"
							label="Has Collection Scan Delay"
							value={values.has_collection_scan_delay}
						/>

						<BooleanDropDown
							id="requires_tracking_number_for_asn"
							label="Requires Tracking Number For ASN"
							value={values.requires_tracking_number_for_asn}
						/>

						<BooleanDropDown
							id="send_non_despatched_order_emails"
							label="Send Non Despatched Order Emails"
							value={values.send_non_despatched_order_emails}
						/>

						<BooleanDropDown
							id="requires_contact_details_for_domestic_orders"
							label="Requires Contact Details For Domestic Orders"
							value={values.requires_contact_details_for_domestic_orders}
						/>

						<TextfieldFormik
							name="sendcloud_brand_domain"
							label="Sendcloud Brand Domain"
						/>

						<TextfieldFormik
							name="sendcloud_sender_address_id"
							label="Sendcloud Sender Address ID"
							type="number"
						/>

						<Box className="align-content-right">
							<Button
								variant="outlined"
								color="secondary"
								className="back-button"
								onClick={() => handleBackButton()}
							>
								Back
							</Button>

							<Button
								type="submit"
								variant="contained"
							>
								Confirm
							</Button>
						</Box>

					</Form>
				)}
			</Formik>
		</>
	);
}