import React, {
	useState,
	useContext,
	useCallback,
	useMemo,
	useEffect,
} from "react";
import "./StatusChecker.scss";
import useFetchData from "../Shared/FetchData/FetchData";
import {
	EndDatePicker,
	StartDatePicker
} from "../Shared/DatePicker";
import {
	getDisplayDate,
	getCurrentDateTruncated,
	getFormattedStartTime,
	getFormattedEndTime
} from "../../utils/date.js";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import {
	Box,
	Grid,
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { DataContext } from "../../App";

export default function StatusChecker() {

	const context = useContext(DataContext);
	const initStartDate = getCurrentDateTruncated();
	const initEndDate = getCurrentDateTruncated();
	initStartDate.setDate(initStartDate.getDate() - 1);

	const [state, setState] = useState({
		startDate: initStartDate,
		endDate: initEndDate,
		dataBezosWithStatus: [],
		dataBezosWithStatusIsLoading: false,
	});

	const params = useMemo(() => new URLSearchParams({
		startTime: getFormattedStartTime(state.startDate),
		endTime: getFormattedEndTime(state.endDate),
	}), [state.startDate, state.endDate]);

	const dataSellers = useFetchData(useCallback(() =>
		context.dataProvider.getSellers(),
	[context.dataProvider]));

	const dataBezos = useFetchData(useCallback(() =>
		context.dataProvider.getBezosNotWarehouse(params),
	[context.dataProvider, params]));

	const dataWarehouse = useFetchData(useCallback(() =>
		context.dataProvider.getWarehouseNotBezos(params),
	[context.dataProvider, params]));

	const dataStatus = useFetchData(useCallback(() =>
		context.dataProvider.getStatusChecker(params),
	[context.dataProvider, params]));

	const dataSyncUnintended = useFetchData(useCallback(() =>
		context.dataProvider.getOrdersSyncUnintended(params),
	[context.dataProvider, params]));

	useEffect(() => {
		addSellerStatus();
	}, [dataBezos.results, dataSellers.results]);

	const addSellerStatus = () => {
		if (dataBezos.results.length === 0 || dataSellers.results.length === 0) return;

		setState(previousState => ({
			...previousState,
			dataBezosWithStatusIsLoading: true,
		}));

		const sellerCodeMap = new Map(dataSellers.results.map(seller => [seller.code, seller]));

		const getStatus = dataBezos.results.map(bezosItem => {
			const matchedSeller = sellerCodeMap.get(bezosItem.seller_code);
			return matchedSeller ? { ...bezosItem, status: matchedSeller.status } : bezosItem;
		});

		setState(previousState => ({
			...previousState,
			dataBezosWithStatus: getStatus,
			dataBezosWithStatusIsLoading: false,
		}));
	};

	const page_data = {
		bezos: {
			resultsData: state.dataBezosWithStatus.sort((a, b) => a.order_date > b.order_date ? -1 : 1),
			resultsDataTotal: state.dataBezosWithStatus.length,
			status: dataBezos.status,
			isLoading: state.dataBezosWithStatusIsLoading,
		},
		warehouse: {
			resultsData: dataWarehouse.results.sort((a, b) => a.order_date > b.order_date ? -1 : 1),
			resultsDataTotal: dataWarehouse.results.length,
			status: dataWarehouse.status,
			isLoading: dataWarehouse.isLoading,
		},
		status: {
			resultsData: dataStatus.results.sort((a, b) => a.bezos_order_date > b.bezos_order_date ? -1 : 1),
			resultsDataTotal: dataStatus.results.length,
			status: dataStatus.status,
			isLoading: dataStatus.isLoading,
		},
		sync_unintended: {
			resultsData: dataSyncUnintended.results.sort((a, b) => a.bezos_order_date > b.bezos_order_date ? -1 : 1),
			resultsDataTotal: dataSyncUnintended.results.length,
			status: dataSyncUnintended.status,
			isLoading: dataSyncUnintended.isLoading,
		},
	};

	const onStartDateChange = (newValue) => {
		setState(previousState => {
			return {
				...previousState,
				startDate: newValue
			};
		});
	};

	const onEndDateChange = (newValue) => {
		setState((previousState) => {
			return {
				...previousState,
				endDate: newValue
			};
		});
	};

	const FrozenStatus = () => {
		return (
			<Typography
				className="frozen-status"
				variant="span"
			>
				Frozen
			</Typography>
		);
	};

	const PageHeading = () => {
		return (
			<Grid item xs={6} sm={8} lg={12} xl={12} container mb={2}>
				<Grid item xs="auto" mr={1} mb={2}>
					<Typography variant="h5">
						Status Checker
					</Typography>
				</Grid>
				<Grid item xs />
				<Grid item xs="auto">
					<Grid item xs="auto">
						<StartDatePicker onChange={onStartDateChange} date={state.startDate} />
						<EndDatePicker onChange={onEndDateChange} date={state.endDate} />
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const TableDataBezosNotInWarehouse = () => {
		if (page_data.bezos.resultsData.length > 0) {
			return (
				page_data.bezos.resultsData.map((item, index) =>
					<TableRow key={index}>
						<TableCell>
							{item.order_number}
							{item.status === "Frozen" && <FrozenStatus />}
						</TableCell>
						<TableCell>{item.order_date !== "" ? getDisplayDate(item.order_date) : ""}</TableCell>
						<TableCell sx={{ maxWidth: "300px" }}>{item.courier_service}</TableCell>
						<TableCell><StatusBox status={item.order_status} statusType="order" /></TableCell>
						<TableCell>{item.seller_code}</TableCell>
					</TableRow>
				)
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan="5" className='table-error-message-text'>No results found</TableCell>
				</TableRow>
			);
		}
	};
	const TableHeaderBezosNotInWarehouse = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", mb: 5 }} elevation={2}>
				<LoadableContent isLoading={page_data.bezos.isLoading}>
					<Typography variant="h6" sx={{ marginBottom: 2 }}>
						Orders in Bezos but not in the warehouse
						<RecordsTotal total={page_data.bezos.resultsDataTotal} />
					</Typography>
					<TableContainer sx={{ maxHeight: 600, overflow: "auto" }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell className="table-header">Bezos order number</TableCell>
									<TableCell className="table-header">Bezos order date</TableCell>
									<TableCell className="table-header">Courier service</TableCell>
									<TableCell className="table-header">Bezos order status</TableCell>
									<TableCell className="table-header">Seller</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableDataBezosNotInWarehouse />
							</TableBody>
						</Table>
					</TableContainer>
				</LoadableContent>
			</Paper>);
	};

	const TableDataWarehouseNotInBezos = () => {
		if (page_data.warehouse.resultsData.length > 0) {
			return (
				page_data.warehouse.resultsData.map((item, index) =>
					<TableRow key={index}>
						<TableCell>{item.order_number}</TableCell>
						<TableCell>{item.order_date !== "" ? getDisplayDate(item.order_date) : ""}</TableCell>
						<TableCell><StatusBox status={item.order_status} statusType="order" /></TableCell>
						<TableCell>{item.warehouse_code}</TableCell>
					</TableRow>
				)
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan="4" className='table-error-message-text'>No results found</TableCell>
				</TableRow>
			);
		}
	};
	const TableHeaderWarehouseNotInBezos = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", mb: 5 }} elevation={2}>
				<LoadableContent isLoading={page_data.warehouse.isLoading}>
					<Typography variant="h6" sx={{ marginBottom: 2 }}>
						Orders in the warehouse but not in Bezos
						<RecordsTotal total={page_data.warehouse.resultsDataTotal} />
					</Typography>
					<TableContainer sx={{ maxHeight: 600, overflow: "auto" }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell className="table-header">Warehouse order number</TableCell>
									<TableCell className="table-header">Warehouse order date</TableCell>
									<TableCell className="table-header">Warehouse status</TableCell>
									<TableCell className="table-header">Warehouse</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableDataWarehouseNotInBezos />
							</TableBody>
						</Table>
					</TableContainer>
				</LoadableContent>
			</Paper >);
	};

	const TableDataStatus = ({ tableData }) => {
		if (tableData.resultsData.length > 0) {
			return (
				tableData.resultsData.map((item, index) =>
					<TableRow key={index}>
						<TableCell>{item.bezos_order_number}</TableCell>
						<TableCell>{item.bezos_order_date !== "" ? getDisplayDate(item.bezos_order_date) : ""}</TableCell>
						<TableCell><StatusBox status={item.bezos_order_status} statusType="order" /></TableCell>
						<TableCell>{item.warehouse_order_number}</TableCell>
						<TableCell>{item.warehouse_order_date !== "" ? getDisplayDate(item.warehouse_order_date) : ""}</TableCell>
						<TableCell><StatusBox status={item.warehouse_order_status} statusType="order" /></TableCell>
						<TableCell>{item.warehouse_code}</TableCell>
						<TableCell>{item.seller_code}</TableCell>
					</TableRow>
				)
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan="8" className='table-error-message-text'>No results found</TableCell>
				</TableRow>
			);
		}
	};
	const TableHeaderStatus = ({ tableTitle, tableData }) => {
		return (
			<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
				<LoadableContent isLoading={page_data.status.isLoading}>
					<Typography variant="h6" sx={{ marginBottom: 2 }}>
						{tableTitle}
						<RecordsTotal total={tableData.resultsDataTotal} />
					</Typography>
					<TableContainer sx={{ maxHeight: 600, overflow: "auto" }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell className="table-header">Bezos order number</TableCell>
									<TableCell className="table-header">Bezos order date</TableCell>
									<TableCell className="table-header">Bezos status</TableCell>
									<TableCell className="table-header">Warehouse order number</TableCell>
									<TableCell className="table-header">Warehouse order date</TableCell>
									<TableCell className="table-header">Warehouse status</TableCell>
									<TableCell className="table-header">Warehouse</TableCell>
									<TableCell className="table-header">Seller</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableDataStatus
									tableData={tableData}
								/>
							</TableBody>
						</Table>
					</TableContainer>
				</LoadableContent>
			</Paper>);
	};

	return (
		<Box id="status-checker">
			<PageHeading />
			<TableHeaderBezosNotInWarehouse />
			<TableHeaderWarehouseNotInBezos />
			<TableHeaderStatus
				tableTitle="Orders where the warehouse status is different to the Bezos status"
				tableData={page_data.status}
			/>
			<TableHeaderStatus
				tableTitle="Orders that should not have been synced to the warehouse"
				tableData={page_data.sync_unintended}
			/>
		</Box>
	);
}
