import React, { useState } from "react";
import { LoadableContent } from "../LoadableContent/LoadableContent";
import { RecordsTotal } from "../ResultsFound/ResultsFound";
import { removeUnderScore } from "../../../utils/text";
import { getDisplayDate } from "../../../utils/date.js";
import {
	Table,
	TableContainer,
	TableBody,
	TableCell,
	Pagination,
	TableHead,
	TableRow,
	Grid,
	Box,
	Typography
} from "@mui/material";
import "./Table.scss";
import UseFetchPaginated from "../FetchData/FetchDataPaginated";
import * as tableConstants from "../Config/TableConfig";
import PageRowsDropDown from "../PageRows/PageRows";
import { CSVLink } from "react-csv";
import { DownloadButton } from "../Buttons/Buttons";
import { AlertBox } from "../Alert/Alert";

export default function DataTable({
	headers,
	dateHeaders,
	seller,
	url,
	params,
	currentPage,
	rowsPerPage,
	onChangePageRow,
	csvFilename,
	onClick,
}) {

	const pageRowOptions = tableConstants.PAGE_ROW_OPTIONS;
	const [state, setState] = useState({
		page: currentPage,
		csvData: [],
	});

	const data = UseFetchPaginated(
		seller,
		url,
		params,
		rowsPerPage
	);

	if (data === undefined) {
		return (
			<Grid item xs={12} lg={12} mb={4}>
				<AlertBox
					severity={"error"}
					message={"Error retrieving data, please contact support."}
				/>
			</Grid>
		);
	}

	const getTableRow = (obj, prop) => {
		const parts = prop.split(".");

		if (Array.isArray(parts)) {
			const last = parts.length > 1 ? parts.pop() : parts;
			let l = parts.length,
				i = 1,
				current = parts[0];

			while ((obj = obj[current]) && i < l) {
				current = parts[i];
				i++;
			}

			if (typeof obj === "object") {
				return obj[last];
			}

			if (typeof obj === "boolean") {
				return obj === true ? "Yes" : "No";
			}

			return obj;

		} else {
			return "Invalid Data";
		}
	};

	const getPageValue = (e) => {

		let paginationNextPage;

		if (e.currentTarget.textContent.length > 0) {
			paginationNextPage = Number(e.currentTarget.innerText);
		} else if (e.currentTarget.querySelector("svg").getAttribute("data-testid") === "NavigateNextIcon") {
			paginationNextPage = Number(state.page) + 1;
		} else if (e.currentTarget.querySelector("svg").getAttribute("data-testid") === "NavigateBeforeIcon") {
			paginationNextPage = Number(state.page) - 1;
		} else {
			paginationNextPage = Number(state.page);
		}

		return paginationNextPage;
	};

	let paginationView;
	if (data.recordsTotal <= rowsPerPage) {
		paginationView = null;
	} else {
		paginationView = (
			<Pagination
				count={data.allData.length}
				page={state.page}
				color="primary"
				siblingCount={0}
				boundaryCount={1}
				onChange={(input) => {
					setState(previousState => {
						return {
							...previousState,
							page: getPageValue(input, state.page)
						};
					});
				}}
			/>
		);
	}

	const onClickCombineData = () => {
		if (!data.allData) return [];
		return data.allData.flat();
	};

	const TableHeader = () => {
		return (
			headers.length > 0 ?
				<TableRow sx={{ verticalAlign: "bottom" }}>
					{headers.map((header, i) => {
						return (
							<TableCell key={i}>
								{removeUnderScore(header)}
							</TableCell>
						);
					})}
				</TableRow>
				:
				<TableRow>
					<TableCell colSpan={headers.length} className='table-error-message-text'>
						No results found
					</TableCell>
				</TableRow>
		);
	};

	const TableData = () => {
		if (data.allData.length > 0) {
			return (
				data.allData[state.page - 1].map((item, i) => (
					<TableRow key={i}>
						{headers.map((header, i) =>
							dateHeaders.some(value => header.includes(value)) ?
								<TableCell key={i}>{getDisplayDate(getTableRow(item, header))}</TableCell>
								:
								<TableCell key={i}>{getTableRow(item, header)}</TableCell>
						)}
					</TableRow>
				))
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan={headers.length} className='table-error-message-text'>No results
						found</TableCell>
				</TableRow>
			);
		}
	};

	const TableMain = () => {
		return (
			<LoadableContent LoadableContent={data.isLoading} message={data.loadingProgress}>
				<TableContainer sx={{ maxHeight: 600, overflow: "auto", mt: 0 }}>
					<Table stickyHeader>
						<TableHead sx={{ textTransform: "capitalize" }}>
							<TableHeader />
						</TableHead>
						<TableBody>
							<TableData />
						</TableBody>
					</Table>
				</TableContainer>

				<Grid
					item
					container
					className="table-footer"
					mt={3}
					xs={3}
					sm={4}
					lg={10}
					xl={12}
				>
					<Grid item mb={2}>
						<Typography variant="h5">
							<RecordsTotal total={data.recordsTotal} />
						</Typography>
					</Grid>

					<Grid item xs />

					<Grid item mb={2} mr={2}>
						{paginationView}
					</Grid>

					<Grid item mb={2}>
						<PageRowsDropDown
							resultsTotalCount={data.recordsTotal}
							rowsPerPage={rowsPerPage}
							pageRowOptions={pageRowOptions}
							onChangePageRow={onChangePageRow}
						/>
					</Grid>

					<Grid item xs />

					{csvFilename && data.allData.length > 0 &&
						<Grid item mb={2} className="download-csv-button">
							<CSVLink
								data={onClickCombineData()}
								filename={csvFilename}
								onClick={() => onClick ? onClick(csvFilename) : {}}
								id={csvFilename}
							>
								<DownloadButton
									onClick={() => {}}
									icon={true}
									disabled={!data.status}
									text={"Download CSV"}
								/>
							</CSVLink>
						</Grid>
					}

				</Grid>

			</LoadableContent>
		);
	};

	return (
		<Box className="data-table">
			<TableMain />
		</Box>
	);

}