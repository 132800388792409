import React, {
	useState,
	useEffect,
	useContext,
	useCallback,
	useMemo
} from "react";
import "./Despatch.scss";
import useFetchData from "../Shared/FetchData/FetchData";
import { 
	getCurrentDateTruncated,
	getUpdatedDate
} from "../../utils/date.js";
import {
	StartDatePicker,
	EndDatePicker
} from "../Shared/DatePicker";
import TimePicker from "../Shared/TimePicker/TimePicker";
import {
	Button,
	Grid,
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Box,
	Snackbar,
} from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { DataContext } from "../../App";
import { LoadingStatusTable } from "../Shared/LoadingStatus/LoadingStatus";
import { LoadingStatusBox } from "../Shared/LoadingStatus/LoadingStatus";
import { AlertBox } from "../Shared/Alert/Alert";
import { DownloadCSV } from "../Shared/DownloadCSV/DownloadCSV.jsx";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound.jsx";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import MediaCard from "../Shared/Cards/MediaCard";
import { getDisplayDateTime } from "../../utils/date.js";
import { getFormattedCutoffTime } from "../../utils/time.js";

export default function Despatch() {

	const context = useContext(DataContext);
	const startDate = getCurrentDateTruncated();
	const endDate = getCurrentDateTruncated();
	startDate.setDate(startDate.getDate() - 1);

	const [state, setState] = useState({
		isLoaded: false,
		despatchData: {},
		despatchTableData: {},
		startDate: startDate,
		endDate: endDate,
		time: "00:00",
		warehouses: [],
		disableTicketCreation: false,
		showAlert: false,
		alertSeverity: "error",
		alertMessage: "",
	});

	const params = useMemo(() => new URLSearchParams({
		startTime: getUpdatedDate(state.startDate, state.time, "despatch"),
		endTime: getUpdatedDate(state.endDate, state.time, "despatch"),
	}), [state.startDate, state.endDate, state.time]);

	const onStartDateChange = (newValue) => {
		setState(previousState => {
			return {
				...previousState,
				startDate: newValue,
				isLoaded: false
			};
		});
	};

	const onEndDateChange = (newValue) => {
		setState((previousState) => {
			return {
				...previousState,
				endDate: newValue,
				isLoaded: false
			};
		});
	};

	const onTimeChange = (newValue) => {
		setState(previousState => {
			return {
				...previousState,
				time: newValue,
				isLoaded: false
			};
		});
	};

	const despatchDetails = useFetchData(useCallback(() =>
		context.dataProvider.getDespatchDetails(params),
	[context.dataProvider, params]));

	const warehouses = useFetchData(useCallback(() =>
		context.dataProvider.getWarehouses(params),
	[context.dataProvider, params]));
	
	const despatchTotals = useFetchData(useCallback(() => 
		context.dataProvider.getDespatchTotals(params),
	[context.dataProvider, params]));

	const page_data = {
		despatch: {
			resultsData: despatchDetails.results,
			status: despatchDetails.status,
			isLoading: despatchDetails.isLoading,
		},
		warehouse: {
			resultsData: warehouses.results,
			status: warehouses.status,
			isLoading: warehouses.isLoading,
		},
		despatchTotals: {
			resultsData: despatchTotals.results,
			status: despatchTotals.status,
			isLoading: despatchTotals.isLoading,
		},
	};

	useEffect(() => {
		if (page_data.despatch.resultsData && state.warehouses) {
			const mergedData = page_data.despatch.resultsData.map(item => {
				return {
					...item,
					warehouse_name: state.warehouses[item.warehouse_id]?.name,
					bezos_order_date: getDisplayDateTime(item.bezos_order_date),
					warehouse_order_date: getDisplayDateTime(item.warehouse_order_date),
					warehouse_cutoff_time: getFormattedCutoffTime(state.warehouses[item.warehouse_id]?.cutoff_time_hour, state.warehouses[item.warehouse_id]?.cutoff_time_minute),
				};
			});
			setState(previousState => {
				return {
					...previousState,
					despatchTableData: mergedData,
				};
			});
			
		}
	}, [page_data.despatch.resultsData, state.warehouses]);

	useEffect(() => {
		const getWarehouseData = () => {
			setState(previousState => {
				return {
					...previousState,
					warehouses: page_data.warehouse.resultsData.reduce((acc, w) => {
						acc[w.id] = w;
						return acc;
					}, {}),
				};
			});
		};
		getWarehouseData();
	}, [page_data.warehouse.resultsData]);

	const createTicket = (ticket) => {
		context.dataProvider.getDespatchTicket(ticket)
			.then(response => {
				const ticketId = response.data.id;
				setState(previousState => {
					return {
						...previousState,
						disableTicketCreation: false,
						alertSeverity: "success",
						alertMessage: "Successfully created a new ticket (" + ticketId + ")",
					};
				});
			})
			.catch((err) => {
				setState(previousState => {
					return {
						...previousState,
						disableTicketCreation: false,
						alertSeverity: "error",
						alertMessage: "Error creating ticket: " + err,
					};
				});
			});

		setState(previousState => {
			return {
				...previousState,
				showAlert: true,
			};
		});
	};

	const createWarehouseTicket = (warehouse) => {

		setState(previousState => {
			return {
				...previousState,
				disableTicketCreation: true
			};
		});

		const checkerDate = new Date().toISOString().substring(0, 11).replace("T", " ").replace(/-/g, "");
		const thisWarehouse = state.warehouses[warehouse];
		const thisWarehouseData = page_data.despatch.resultsData.filter(d => d.warehouse_id.toString() === warehouse);

		let csvContent = "Bezos Order Number,Bezos Order Status,Client Name,Warehouse Order Number,Warehouse Order Status,Bezos Order Date,Warehouse Order Date,Comments\n";

		for (const item of thisWarehouseData) {
			csvContent +=
                item.bezos_order_number + "," +
                item.bezos_order_status + "," +
                item.client_name + "," +
                item.warehouse_order_number + "," +
                item.warehouse_order_status + "," +
                (item.bezos_order_date === null ? "" : new Date(item.bezos_order_date).toISOString().substring(0, 16).replace("T", " ")) + "," +
                (item.warehouse_order_date === null ? "" : new Date(item.warehouse_order_date).toISOString().substring(0, 16).replace("T", " ")) + "," +
                "\"" + item.comment_text.replace("\"", "'") + "\"<br/>";
		}

		let ticketContent = "<Box>Hello " + thisWarehouse.contact_name + ",<br/><br/>" +
            "We've noticed in the system that these " + thisWarehouseData.length + " orders are not marked as despatched. See attached CSV file.<br/><br/>" +
            "Could you confirm these orders will be despatched?<br/><br/>" +
            "Thank you.<br/><br/>" +
            "Kind regards,<br/>" +
            "Bezos Operations</Box>";

		const ticket = {
			"subject": checkerDate + " Despatch Checker",
			"contact": {
				"first_name": thisWarehouse.contact_name,
				"last_name": "",
				"email": thisWarehouse.contact_email,
			},
			"description": ticketContent,
			"status": "open",
			"attachments": [{
				"public": false,
				"base_64_content": btoa(csvContent),
				"filename": checkerDate + "_despatch_checker.csv"
			}]
		};
		createTicket(ticket);
	};

	const createSellerTicket = (seller) => {

		setState(previousState => {
			return {
				...previousState,
				disableTicketCreation: true
			};
		});

		const thisSellerData = page_data.despatch.resultsData.filter(d => d.client_name === seller);

		let ticketContent = "";

		if (thisSellerData.length > 1) {
			ticketContent = "<Box>Hello<br/><br/>" +
                "I just want to bring to your attention that the following orders were not despatched today:<br/><ul>" +
                thisSellerData.map(d => "<li>" + d.bezos_order_number) +
                "</ul>" +
                "We are pushing our warehouse partner really hard so they can fulfil and despatch these orders tomorrow.<br/><br/>" +
                "Sorry for the inconvenience that this might cause you.<br/><br/>" +
                "Please, let us know if you need anything else.<br/><br/>" +
                "Regards,<br/>" +
                "Bezos Operations</Box>";
		} else {
			ticketContent = "<Box>Hello<br/><br/>" +
                "I just want to bring to your attention that the order " +
                thisSellerData[0].bezos_order_number +
                " was not despatched today. " +
                "We are pushing our warehouse partner really hard so they can fulfil and despatch this order tomorrow.<br/><br/>" +
                "Sorry for the inconvenience that this might cause you.<br/><br/>" +
                "Please, let us know if you need anything else.<br/><br/>" +
                "Regards,<br/>" +
                "Bezos Operations</Box>";
		}

		const res = context.dataProvider.getContactData(seller)
			.then(response => {
				return response.data;
			})
			.catch(() => {
				return [{
					"first_name": "Unmapped",
					"last_name": "Seller",
					"email": "",
				}];
			});

		res.then(contact => {
			const ticket = {
				"subject": "Undespatched Orders",
				"contact": contact[0],
				"description": ticketContent,
				"status": "open",
				"attachments": []
			};
			createTicket(ticket);
		});

	};

	const NullResults = () => {
		return (
			<AlertBox
				onClose={onAlertHandleClose}
				severity={"warning"}
				message={"No uncollected orders found for this time period."}
			/>
		);
	};

	const GetDespatchCheckerTable = () => {

		const TableData = () => {
			if (page_data.despatch.resultsData.length === 0) {
				return (
					<TableRow>
						<TableCell colSpan="9" className='table-error-message-text'>No results found</TableCell>
					</TableRow>
				);
			}

			return (
				state.despatchTableData.map((item, i) =>
					<TableRow key={i}>
						<TableCell>{item.bezos_order_number}</TableCell>
						<TableCell>{item.bezos_order_status}</TableCell>
						<TableCell>{item.warehouse_name}</TableCell>
						<TableCell>{item.client_name}</TableCell>
						<TableCell>{item.warehouse_order_number}</TableCell>
						<TableCell>
							<StatusBox 
								status={item.warehouse_order_status} 
								statusType={"order"}
							/>
						</TableCell>
						<TableCell>{item.bezos_order_date}</TableCell>
						<TableCell>{item.warehouse_order_date}</TableCell>
						<TableCell>{item.warehouse_cutoff_time}</TableCell>
					</TableRow>
				)
			);
		};

		return (
			<Paper sx={{ p: 2, mt: 4, width: "100%" }} elevation={2}>
				<Typography variant="h6" mb={2}>
                    Details <RecordsTotal total={state.despatchTableData.length} />
				</Typography>
				<TableContainer sx={{ maxHeight: 600, overflow: "auto", mb:3 }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell className="table-header">Bezos Order Number</TableCell>
								<TableCell className="table-header">Bezos Order Status</TableCell>
								<TableCell className="table-header">Warehouse</TableCell>
								<TableCell className="table-header">Client</TableCell>
								<TableCell className="table-header">Warehouse Order Number</TableCell>
								<TableCell className="table-header">Warehouse Order Status</TableCell>
								<TableCell className="table-header">Bezos Order Date</TableCell>
								<TableCell className="table-header">Warehouse Order Date</TableCell>
								<TableCell className="table-header">Warehouse Cutoff Time</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{page_data.despatch.isLoading ?
								<LoadingStatusTable message={"Loading..."} colSpan={9} />
								:
								<TableData />
							}
						</TableBody>
					</Table>

				</TableContainer>
				{state.despatchTableData.length > 0 &&
					<DownloadCSV 
						data={state.despatchTableData}
						csvFilename={"despatch_checker_table"}
						isLoaded={state.isLoaded}
					/>
				}
			</Paper>
		);
	};

	const GetSellerSummary = () => {

		if (page_data.despatch.isLoading) {
			return <LoadingStatusBox/>;
		}

		const despatchData = page_data.despatch.resultsData;
		const sellerTotals = despatchData.reduce((acc, d) => {
			if (acc[d.client_name] != null) {
				acc[d.client_name]++;
			} else {
				acc[d.client_name] = 1;
			}
			return acc;
		}, {});

		const sellerListItems = [];

		for (const st of Object.keys(sellerTotals).sort()) {
			if (sellerTotals[st] > 0) {
				sellerListItems.push(
					<li key={st}>
						<Button
							variant="contained"
							disabled={state.disableTicketCreation}
							onClick={createSellerTicket.bind(this, st)}
						>
							<ConfirmationNumberIcon fontSize="small" sx={{ marginRight: "0.5rem" }} />Create ticket
						</Button>
						<Box className="dispatch-ticket-button">{st} : {sellerTotals[st]}</Box>
					</li>);
			}
		}

		return (
			<>
				<Typography component="h2" variant="h6" mb={1}>
                    By Seller
				</Typography>

				{sellerListItems.length === 0 ?
					<NullResults />
					:
					<ul className="collection-status-list">
						{sellerListItems}
					</ul>
				}
				
			</>
		);

	};

	const GetWarehouseSummary = () => {

		if (page_data.despatch.isLoading) {
			return <LoadingStatusBox/>;
		}

		const warehouses = state.warehouses;
		const despatchData = page_data.despatch.resultsData;
		const warehouseListItems = [];

		for (const w of Object.keys(warehouses).sort()) {
			const warehouseOrders = despatchData.filter(d => d.warehouse_id.toString() === w).length;

			if (warehouseOrders > 0) {
				warehouseListItems.push(
					<li key={w}>
						<Button
							variant="contained"
							disabled={state.disableTicketCreation}
							onClick={createWarehouseTicket.bind(this, w)}
						>
							<ConfirmationNumberIcon fontSize="small" sx={{ marginRight: "0.5rem" }} />Create ticket
						</Button>
						<Box className="dispatch-ticket-button">{warehouses[w].name}: {warehouseOrders}</Box>
					</li>);
			}
		}

		return (
			<>
				<Typography component="h2" variant="h6" mb={1}>
                    By Warehouse
				</Typography>

				{warehouseListItems.length === 0 ?
					<NullResults />
					:
					<ul className="collection-status-list">
						{warehouseListItems}
					</ul>
				}
			</>
		);
	};

	const DespatchTotals = () => {
		return (
			<Box sx={{ width: "100%", mb: 4 }}>
				<Grid container spacing={3} mb={1}>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<MediaCard
							loadingStatus={page_data.despatchTotals.isLoading}
							titleTextLong="Orders not despatched"
							titleTextShort="Not Despatched Orders"
							bodyText={page_data.despatchTotals.resultsData.non_despatched_orders}
							imageName="sync-problem"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<MediaCard
							loadingStatus={page_data.despatchTotals.isLoading}
							titleTextLong="Orders despatched"
							titleTextShort="Despatched Orders"
							bodyText={page_data.despatchTotals.resultsData.despatched_orders}
							imageName="warehouse"
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<MediaCard
							loadingStatus={page_data.despatchTotals.isLoading}
							titleTextLong="Total orders despatched and not despatched"
							titleTextShort="Total Orders"
							bodyText={page_data.despatchTotals.resultsData.total_orders}
							imageName="bezos-logo"
						/>
					</Grid>
				</Grid>
			</Box>
		);
	};

	const onAlertHandleClose = (reason) => {
		if (reason === "clickaway") {
			return;
		}

		setState(previousState => {
			return {
				...previousState,
				showAlert: false,
			};
		});
	};

	const PageHeading = () => {
		return (
			<Grid item xs={3} sm={4} lg={10} xl={12} container mb={2}>
				<Grid item xs="auto" mr={1} mb={2}>
					<Typography variant="h5">
                        Despatch Checker
					</Typography>
				</Grid>
				<Grid item xs />
				<Grid item xs="auto">
					<Grid item xs="auto">
						<Grid item xs={6} sm={10} lg={12} xl={12}>
							<StartDatePicker 
								onChange={onStartDateChange}
								date={state.startDate} 
							/>
							<EndDatePicker 
								onChange={onEndDateChange} 
								date={state.endDate} 
							/>
							<TimePicker
								onTimeChange={onTimeChange}
								time={state.time}
							/>							
						</Grid>						
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const Summary = () => {
		return (
			<>
				<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
					<Typography variant="h6" mb={2}>
                        Summary
					</Typography>
					<Grid item xs={12} mb={2}>

						<Grid container spacing={2}>

							<Grid item xs={12} md={6} xl={6}>
								<Paper sx={{ p: 2, width: "100%", minHeight: "100%" }} elevation={2}>
									<GetWarehouseSummary />
								</Paper>
							</Grid>

							<Grid item xs={12} md={6} xl={6}>
								<Paper sx={{ p: 2, width: "100%", minHeight: "100%" }} elevation={2}>
									<GetSellerSummary />
								</Paper>
							</Grid>

						</Grid>

					</Grid>
				</Paper>
			</>
		);
	};

	return (
		<Box id="despatch">
			<PageHeading />

			<Grid container>

				<Grid item xs={12} lg={12}>
					<DespatchTotals />
				</Grid>

				<Grid item xs={12} lg={12}>
					<Summary />
				</Grid>

				<Grid item xs={12} lg={12}>
					<GetDespatchCheckerTable />
				</Grid>

			</Grid>

			<Snackbar open={state.showAlert} autoHideDuration={8000} onClose={onAlertHandleClose} mb={2}>
				<Box>
					<AlertBox
						onClose={onAlertHandleClose}
						severity={state.alertSeverity}
						message={state.alertMessage}
					/>
				</Box>
			</Snackbar>

		</Box>
	);

}
