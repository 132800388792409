import React from "react";
import { MenuItem } from "@mui/material";
import DropDown from "../DropDown/DropDown";

export default function PageRowsDropDown ({ 
	resultsTotalCount, 
	rowsPerPage,
	pageRowOptions, 
	onChangePageRow
}) {

	const map = pageRowOptions.map((item, i) => (
		item <= resultsTotalCount ?
			<MenuItem
				key={i}
				value={item}
			>
				{item}
			</MenuItem>
			: null
	));

	const PageRows = () => {
        
		if (resultsTotalCount <= rowsPerPage) return;

		return (
			<>
				<DropDown
					label={"Rows"}
					id={"pageRows"}
					list={pageRowOptions}
					onChangeSelect={onChangePageRow}
					selected={rowsPerPage}
					sx={{ maxWidth: 100 }}
					size={"small"}
					map={map}
				/>
			</>
		);

	};

	return (
		<PageRows />
	);
}
