import React, {
	useCallback,
	useContext
} from "react";
import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	Box
} from "@mui/material";
import useFetchData from "../Shared/FetchData/FetchData";
import { LoadingStatusBox } from "../Shared/LoadingStatus/LoadingStatus";
import { DataContext } from "../../App";

export default function MissionControl() {

	const context = useContext(DataContext);
	const orderData = useFetchData(useCallback(() =>
		context.dataProvider.getPerformanceSummary(), [context.dataProvider]));

	const page_data = {
		metrics: {
			resultsDataMonth: orderData.results.orders_by_month,
			resultsDataWeek: orderData.results.orders_by_week,
			status: orderData.status,
			isLoading: orderData.isLoading,
		},
	};

	const NullDataMessage = () => {
		return (<Typography>No Data Found</Typography>);
	};

	const getMetricsTable = (tableData) => {

		return (

			page_data.metrics.status === false ?
				<NullDataMessage />
				:
				<Table stickyHeader>
					<TableBody>
						<TableRow>
							<TableCell variant="head" className="table-header">Metric</TableCell>
							{tableData.map((d, i) => <TableCell className="table-header" key={i}>{d.time_period}</TableCell>)}
						</TableRow>
						<TableRow>
							<TableCell variant="head" className="table-header">Orders</TableCell>
							{tableData.map((d, i) => <TableCell key={i}>{d.total_orders}</TableCell>)}
						</TableRow>
						<TableRow>
							<TableCell variant="head" className="table-header">Perfect<br />Orders</TableCell>
							{tableData.map((d, i) => <TableCell key={i}>{d.perfect_orders}</TableCell>)}
						</TableRow>
						<TableRow>
							<TableCell variant="head" className="table-header">Perfect<br />Order<br />Rate</TableCell>
							{tableData.map((d, i) => <TableCell key={i}>{d.total_orders > 0 ? ((d.perfect_orders / d.total_orders) * 100).toFixed(1) + "%" : "-"}</TableCell>)}
						</TableRow>
					</TableBody>
				</Table>

		);
	};

	return (
		<Box id="mission-control">

			<Grid container>

				<Grid item xs={12}>
					<Typography variant="h5" mb={2}>
                        Mission Control
					</Typography>
				</Grid>

				<Grid item xs={12} mb={2}>
					<Grid container spacing={2}>
						<Grid item xs={12} xl={6}>

							<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
								<Typography variant="h6" mb={2}>Last 6 weeks</Typography>
								{page_data.metrics.resultsDataWeek ? getMetricsTable(page_data.metrics.resultsDataWeek) : <LoadingStatusBox/>}
							</Paper>

						</Grid>
						<Grid item xs={12} xl={6}>

							<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
								<Typography variant="h6" mb={2}>Last 6 months</Typography>
								{page_data.metrics.resultsDataMonth ? getMetricsTable(page_data.metrics.resultsDataMonth) : <LoadingStatusBox/>}
							</Paper>

						</Grid>
					</Grid>
				</Grid>

			</Grid>

		</Box>
	);
}
