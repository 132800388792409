import React, { 
	useState,
	useEffect,
} from "react";
import {
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Grid,
	Box,
} from "@mui/material";
import "./Table.scss";
import ColumnVisibility from "./ColumnVisibility";
import { AlertBox } from "../Alert/Alert";
import { removeUnderScore } from "../../../utils/text";
import { getDisplayDateTime } from "../../../utils/date";
import { LoadableContent } from "../LoadableContent/LoadableContent";
import { DownloadCSV } from "../DownloadCSV/DownloadCSV";
import * as tableConstants from "../Config/TableConfig";

function DataTable({ 
	name, 
	data, 
	headers, 
	isLoading,
	isCSVDownloadVisible,
}) {

	if (!data || data.length === 0) {
		return (
			<Grid item xs={12} lg={12} mb={4}>
				<AlertBox
					severity={"error"}
					message={"No results found, please amend your search or contact engineering."}
				/>
			</Grid>
		);
	}

	const localStorageKey = tableConstants.TABLE_LOCAL_STORAGE_KEY;
	const height = tableConstants.TABLE_HEIGHT; 
	const getInitialColumnVisibility = () => {
		const savedSettings = localStorage.getItem(localStorageKey);
		if (savedSettings) {
			const settings = JSON.parse(savedSettings);
			if (settings[name]) {
				return settings[name];
			}
		}
		return headers.reduce((acc, header) => ({
			...acc,
			[header.key]: header.type === "component" ? true : header.defaultColumn
		}), {});
	};

	const [columnsVisibility, setColumnsVisibility] = useState(getInitialColumnVisibility());

	useEffect(() => {
		const savedSettings = localStorage.getItem(localStorageKey);
		const settings = savedSettings ? JSON.parse(savedSettings) : {};
		settings[name] = columnsVisibility;
		localStorage.setItem(localStorageKey, JSON.stringify(settings));
	}, [columnsVisibility]);

	const onVisibilityChange = (newVisibility) => {
		setColumnsVisibility(newVisibility);
	};

	const TableHeader = () => (
		<TableRow sx={{ verticalAlign: "bottom" }}>
			{headers.filter(header => columnsVisibility[header.key]).map(header => (
				<TableCell key={header.key}>
					{header.type === "component" ? "" : removeUnderScore(header.label)}
				</TableCell>
			))}
			<TableCell align="right">
				<ColumnVisibility
					columnsVisibility={columnsVisibility}
					onVisibilityChange={onVisibilityChange}
					headers={headers}
				/>
			</TableCell>
		</TableRow>
	);
	
	const getTableRow = (obj, header) => {
		const valueHandlers = {
			date: (value) => getDisplayDateTime(value),
			boolean: (value) => value ? "Yes" : "No", 
			component: (value, Component) => <Component data={obj} />
		};
		const handleValue = valueHandlers[header.type] ?? ((value) => value);
		return handleValue(obj[header.key], header.component);
	};

	const TableData = () => (
		data.map((item, index) => (
			<TableRow key={item.id || index}>
				{headers.filter(header => columnsVisibility[header.key]).map(header => (
					<TableCell 
						key={header.key}
						align={header.type === "component" ? "right" : "inherit"}
					>
						{getTableRow(item, header)}
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		))
	);

	const TableMain = () => (
		<LoadableContent isLoading={isLoading}>
			<TableContainer sx={{ maxHeight: height, overflow: "auto", mt: 0 }}>
				<Table stickyHeader>
					<TableHead sx={{ textTransform: "capitalize" }}>
						<TableHeader />
					</TableHead>
					<TableBody>
						<TableData />
					</TableBody>
				</Table>
			</TableContainer>
		</LoadableContent>
	);

	return (
		<Box className="data-table">
			<TableMain />
			{isCSVDownloadVisible &&
				<DownloadCSV 
					data={data}
					csvFilename={`${name}-table.csv`}
					isLoaded={isLoading}
					sx={{ mt: 1 }}
				/>
			}
		</Box>
	);
}

export default DataTable;
