import React, {
	useContext,
	useCallback,
} from "react";
import {
	Box,
	Grid,
	Typography,
} from "@mui/material";

import MediaCard from "../Shared/Cards/MediaCard";
import BarChartCard from "../Shared/Cards/BarChartCard";

import useFetchData from "../Shared/FetchData/FetchData";
import { DataContext } from "../../App";

import {
	formatSeconds,
	getFormattedTime
} from "../../utils/time";
import { capitalizeFirstLetterOfEachWord } from "../../utils/text";

export default function QueueLatency() {

	const context = useContext(DataContext);

	const queueStats = useFetchData(useCallback(() =>
		context.dataProvider.getQueueStatistics(),
	[context.dataProvider]));

	const now = new Date();

	return (
		<Box id="queue-latency">
			<Grid item xs={6} sm={8} lg={12} xl={12} container mb={2}>
				<Grid item xs="auto" mr={1}>
					<Typography variant="h5">
						Queue Statistics
					</Typography>
				</Grid>
			</Grid>

			<Box sx={{ width: "100%" }}>
				{queueStats && queueStats.results && queueStats.results.map((item, i) => 
					<Grid container spacing={3} mb={1} key={i}>
						<Grid item xs={12}>
							<Typography variant="h6">
								{capitalizeFirstLetterOfEachWord(item.name.replaceAll("-"," "))}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<MediaCard
								titleTextLong="Queue latency over the previous 5 minutes"
								titleTextShort="Latency"
								bodyText={formatSeconds(item.current_latency)}
								imageName="latency"
								imageHeight="75"
								headingVariant="h6"
								bodyVariant="h6"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<MediaCard
								titleTextLong="Queue throughput (total messages) over the previous hour"
								titleTextShort="Throughput"
								bodyText={item.throughput_last_hour.toLocaleString() + " messages"}
								imageName="throughput"
								imageHeight="75"
								headingVariant="h6"
								bodyVariant="h6"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<BarChartCard
								titleTextShort="Latency (s)"
								chartData={item.latency_history.toReversed()}
								labels={item.latency_history.map((_, idx) => idx).toReversed().map((idx) => getFormattedTime(new Date(now - (idx * 60000 * 5))))}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<BarChartCard
								titleTextShort="Throughput (messages)"
								chartData={item.throughput_history.toReversed()}
								labels={item.throughput_history.map((_, idx) => idx).toReversed().map((idx) => getFormattedTime(new Date(now - (idx * 60000 * 60))))}
							/>
						</Grid>
					</Grid>
				)}
			</Box>
		</Box>
	);
}