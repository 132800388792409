import React, {
	useState,
	useContext,
	useCallback,
	useMemo
} from "react";
import "./CollectionChecker.scss";
import useFetchData from "../Shared/FetchData/FetchData";
import {
	EndDatePicker,
	StartDatePicker
} from "../Shared/DatePicker";
import {
	getDisplayDate,
	getCurrentDateTruncated,
	getFormattedStartTime,
	getFormattedEndTime
} from "../../utils/date.js";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import { EmailTable } from "./components/EmailTable";
import {
	Box,
	Grid,
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import { DataContext } from "../../App";

export default function CollectionChecker() {

	const context = useContext(DataContext);
	const initStartDate = getCurrentDateTruncated();
	const initEndDate = getCurrentDateTruncated();
	initStartDate.setDate(initStartDate.getDate() - 1);

	const [state, setState] = useState({
		startDate: initStartDate,
		endDate: initEndDate,
	});

	const params = useMemo(() => new URLSearchParams({
		startTime: getFormattedStartTime(state.startDate),
		endTime: getFormattedEndTime(state.endDate),
	}), [state.startDate, state.endDate]);

	const apiData = useFetchData(useCallback(() =>
		context.dataProvider.getCollectionData(params),
	[context.dataProvider, params]));

	const collectionStatsResponse = useFetchData(useCallback(() =>
		context.dataProvider.getCollectionStats(params),
	[context.dataProvider, params]));

	const warehousesResponse = useFetchData(useCallback(() =>
		context.dataProvider.getWarehouseResponse(),
	[context.dataProvider]));

	const page_data = {
		collection: {
			resultsData: apiData.results.sort((a, b) => a.order_number > b.order_number ? -1 : 1),
			resultsDataTotal: apiData.results.length,
			status: apiData.status,
			isLoading: apiData.isLoading,
		},
		stats: {
			resultsData: collectionStatsResponse.results.sort((a, b) => a.warehouse_id > b.warehouse_id ? -1 : 1),
			resultsDataTotal: collectionStatsResponse.results.length,
			status: collectionStatsResponse.status,
			isLoading: collectionStatsResponse.isLoading,
		},
		warehouse: {
			resultsData: warehousesResponse.results,
			status: warehousesResponse.status,
			isLoading: warehousesResponse.isLoading,
		},
	};

	const onStartDateChange = (newValue) => {
		setState(previousState => {
			return {
				...previousState,
				startDate: newValue
			};
		});
	};

	const onEndDateChange = (newValue) => {
		setState((previousState) => {
			return {
				...previousState,
				endDate: newValue
			};
		});
	};

	const PageHeading = () => {
		return (
			<Grid item xs={6} sm={8} lg={12} xl={12} container mb={3}>
				<Grid item xs="auto" mr={1} mb={2}>
					<Typography variant="h5">
                        Collection Checker
					</Typography>
				</Grid>
				<Grid item xs />
				<Grid item xs="auto">
					<Grid item xs="auto">
						<StartDatePicker onChange={onStartDateChange} date={state.startDate} />
						<EndDatePicker onChange={onEndDateChange} date={state.endDate} />
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const TableData = () => {
		if (page_data.collection.resultsData.length > 0) {
			return (
				page_data.collection.resultsData.map((item, index) =>
					<TableRow key={index}>
						<TableCell>{item.order_number}</TableCell>
						<TableCell>{item.warehouse}</TableCell>
						<TableCell>{item.courier_service}</TableCell>
						<TableCell>{item.expected_collection !== "" ? getDisplayDate(item.expected_collection) : ""}</TableCell>
						<TableCell>{item.tracking_number}</TableCell>
						<TableCell>{item.client_name}</TableCell>
						<TableCell>{item.contact}</TableCell>
					</TableRow>
				)
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan="7" className='table-error-message-text'>No results found</TableCell>
				</TableRow>
			);
		}
	};
	const TableHeader = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
				<LoadableContent marginBottom={4} isLoading={page_data.collection.isLoading}>
					<Typography variant="h5" sx={{ marginBottom: 4 }}>
						<RecordsTotal total={page_data.collection.resultsDataTotal} />
					</Typography>
					<TableContainer sx={{ maxHeight: 600, overflow: "auto" }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell className="table-header">Order number</TableCell>
									<TableCell className="table-header">Warehouse</TableCell>
									<TableCell className="table-header">Courier service</TableCell>
									<TableCell className="table-header">Expected collection</TableCell>
									<TableCell className="table-header">Tracking number</TableCell>
									<TableCell className="table-header">Seller</TableCell>
									<TableCell className="table-header">Seller contact email</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableData />
							</TableBody>
						</Table>
					</TableContainer>
				</LoadableContent>
			</Paper>);
	};

	return (
		<Box id="collection-checker">
			<PageHeading />
			<TableHeader />
			<EmailTable
				tableTitle="No collected orders by warehouse"
				tableData={page_data.stats.resultsData}
				warehouseData={page_data.warehouse.resultsData}
				warehouseDataIsLoading={page_data.warehouse.isLoading}
				startDate={state.startDate}
				endDate={state.endDate}
			/>
		</Box>
	);
}
