import React, {
	useState,
	useEffect
} from "react";
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import { StatusBox } from "../../Shared/StatusBox/StatusBox";

export default function WarehouseOrderTable({ data }) {

	const [state, setState] = useState({
		isLoading: false,
		warehouseOrderSummary: [],
	});

	const page_data = {
		warehouse_orders: data,
	};

	useEffect(() => {
		setState(previousState => ({
			...previousState,
			warehouseOrderSummary: page_data.warehouse_orders.results,
			isLoading: page_data.warehouse_orders.isLoading,
		}));
	},
	[
		page_data.warehouse_orders.results,
		page_data.warehouse_orders.isLoading,
	]
	);

	if (state.isLoading) {
		return <Typography>Loading...</Typography>;
	}

	return (
		<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
			<Typography variant="h6" sx={{ marginBottom: 4, marginTop: 1 }}>Warehouse Orders</Typography>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell className="table-header">Status</TableCell>
						<TableCell className="table-header">Orders</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{state.warehouseOrderSummary.map((o, i) =>
						<TableRow key={i}>
							<TableCell>
								<StatusBox 
									status={o.status} 
									statusType={"order"}
								/>
							</TableCell>
							<TableCell>{o.orders}</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</Paper>
	);
}