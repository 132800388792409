import React from "react";
import { Grid } from "@mui/material";
import { AlertBox } from "../components/Shared/Alert/Alert";

export const CheckIfSellerSelected = () => {
	return (
		<>
			<Grid item xs={12} lg={12} mb={4}>
				<AlertBox
					severity={"error"}
					message={"Please choose Seller from dropdown"}
				/>
			</Grid>
		</>
	);
};