import React, {
	useState,
	useEffect,
	useContext,
	useCallback,
	useMemo
} from "react";
import "./Summary.scss";
import useFetchData from "../Shared/FetchData/FetchData";
import {
	EndDatePicker,
	StartDatePicker
} from "../Shared/DatePicker";
import {
	getCurrentDateTruncated,
	getFormattedStartTime,
	getFormattedEndTime
} from "../../utils/date.js";
import MediaCard from "../Shared/Cards/MediaCard";
import UnsyncedOrderTable from "./components/UnsyncedOrderTable";
import WarehouseOrderTable from "./components/WarehouseOrderTable";
import {
	Box,
	Grid,
	Typography,
} from "@mui/material";
import { DataContext } from "../../App";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { LightTooltip } from "../Shared/LightTooltip/LightTooltip";

export default function SummaryChecker() {

	const context = useContext(DataContext);
	const initStartDate = getCurrentDateTruncated();
	const initEndDate = getCurrentDateTruncated();
	initStartDate.setDate(initStartDate.getDate() - 1);

	const [state, setState] = useState({
		startDate: initStartDate,
		endDate: initEndDate,
		isLoading: false,
		bezosOrders: "",
		warehouseOrders: "",
		notSyncedOrders: "",
		isBezosOrdersEqualToSum: null,
	});

	const params = useMemo(() => new URLSearchParams({
		startTime: getFormattedStartTime(state.startDate),
		endTime: getFormattedEndTime(state.endDate),
	}), [state.startDate, state.endDate]);

	const totals = useFetchData(useCallback(() =>
		context.dataProvider.getTotals(params),
	[context.dataProvider, params]));

	const unsyncedOrderSummary = useFetchData(useCallback(() =>
		context.dataProvider.getUnsyncedOrders(params),
	[context.dataProvider, params])
	);

	const warehouseOrderSummary = useFetchData(useCallback(() =>
		context.dataProvider.getWarehouseOrders(params),
	[context.dataProvider, params])
	);

	const page_data = {
		card_summary: totals
	};

	useEffect(() => {
		const { bezos_orders, warehouse_orders, not_synced } = page_data.card_summary.results;
	
		if (bezos_orders != null && warehouse_orders != null && not_synced != null) {
			setState(previousState => ({
				...previousState,
				bezosOrders: bezos_orders,
				warehouseOrders: warehouse_orders,
				notSyncedOrders: not_synced,
				isBezosOrdersEqualToSum: warehouse_orders + not_synced === bezos_orders,
				isLoading: page_data.card_summary.isLoading,
			}));
		}
	}, [page_data.card_summary.results, page_data.card_summary.isLoading]);
	
	
	const onStartDateChange = (newValue) => {
		setState(previousState => {
			return {
				...previousState,
				startDate: newValue
			};
		});
	};

	const onEndDateChange = (newValue) => {
		setState((previousState) => {
			return {
				...previousState,
				endDate: newValue
			};
		});
	};

	const SummaryCheck = () => {

		if (state.isBezosOrdersEqualToSum === null) return;
	
		const icon = state.isBezosOrdersEqualToSum
			? <CheckCircleOutlineIcon color="primary" />
			: <CancelIcon color="error" />;

		const message = state.isBezosOrdersEqualToSum
			? "Bezos Orders EQUAL"
			: "Bezos Orders NOT EQUAL";
	
		return (
			<LightTooltip 
				title={`${message} to Unsynced Orders + Warehouse Orders`} 
				arrow
			>
				{icon}
			</LightTooltip>
		);
	};
	
	const PageHeading = () => {
		return (
			<Grid item xs={6} sm={8} lg={12} xl={12} container mb={2} alignItems="center">
				<Grid item xs="auto" mr={1}>
					<Typography variant="h5">
                        Summary
					</Typography>
				</Grid>
				<Grid item xs="auto" mt={1}>
					<SummaryCheck/>
				</Grid>
				<Grid item xs />
				<Grid item xs="auto">
					<Grid item xs="auto" mt={2}>
						<StartDatePicker onChange={onStartDateChange} date={state.startDate} />
						<EndDatePicker onChange={onEndDateChange} date={state.endDate} />
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const PageBody = () => {
		return (
			<>
				<Box sx={{ width: "100%" }}>

					<Grid container spacing={3} mb={1}>

						<Grid item xs={12} sm={6} md={3} lg={3}>
							<MediaCard
								loadingStatus={state.isLoading}
								titleTextLong="Orders in the Bezos system"
								titleTextShort="Bezos Orders"
								bodyText={state.bezosOrders}
								imageName="bezos-logo"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<MediaCard
								loadingStatus={state.isLoading}
								titleTextLong="Orders that haven't synced"
								titleTextShort="Unsynced Orders"
								bodyText={state.notSyncedOrders}
								imageName="sync-problem"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<MediaCard
								loadingStatus={state.isLoading}
								titleTextLong="Orders in the warehouse system"
								titleTextShort="Warehouse Orders"
								bodyText={state.warehouseOrders}
								imageName="warehouse"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} lg={3}>
							<MediaCard
								loadingStatus={state.isLoading}
								titleTextLong="% of orders synced to a warehouse system"
								titleTextShort="Synced %"
								bodyText={state.bezosOrders > 0 ? (state.warehouseOrders * 100 / state.bezosOrders).toFixed(1) + "%" : "-"}
								imageName="synced-percentage"
							/>
						</Grid>
					</Grid>
				</Box>

				<Grid item xs={12} container mt={1} spacing={3}>
					<Grid item xs={12}>
						<UnsyncedOrderTable
							startDate={state.startDate}
							endDate={state.endDate}
							data={unsyncedOrderSummary}
						/>
					</Grid>
					<Grid item xs={12}>
						<WarehouseOrderTable
							startDate={state.startDate}
							endDate={state.endDate}
							data={warehouseOrderSummary}
						/>
					</Grid>
				</Grid>

			</>
		);
	};

	return (
		<Box id="summary-checker">
			<PageHeading />
			<PageBody />
		</Box>
	);
}
