import React, {
	useState,
	useEffect
} from "react";
import "./DebugView.scss";
import { Auth } from "aws-amplify";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import {
	Box,
	Grid,
	Paper,
	Typography,
	Tooltip
} from "@mui/material";

export default function Debug() {

	const [state, setState] = useState({
		token: "",
		refreshToken: false,
		isLoading: false
	});

	useEffect(() => {
		Auth.currentSession()
			.then(data => {
				setState(previousState => {
					return {
						...previousState,
						token: data.accessToken.jwtToken,
						isLoading: false,
					};
				});
			});
	}, [state.refreshToken]);

	const token = state.token;

	const onClickCopyToken = async () => {
		setState(previousState => {
			return {
				...previousState,
				isLoading: true,
				refreshToken: !previousState.refreshToken
			};
		});
		await navigator.clipboard.writeText(token);
	};

	return (
		<Box id="debug">
			<Grid container>
				<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
					<Grid item xs={12}>
						<Typography variant="h5" sx={{ marginBottom: 2 }}>
							Access Token
						</Typography>
						<Grid item xs />
					</Grid>
					<Grid item xs={12}>
						<LoadableContent isLoading={state.isLoading} marginBottom={5}>
							<Tooltip
								title="Click to copy"
								placement="top"
							>
								<Box
									className="debug-token"
									onClick={() => onClickCopyToken()}
								>
									{token}
								</Box>
							</Tooltip>
						</LoadableContent>
					</Grid>
				</Paper>
			</Grid>
		</Box>
	);
}