import React, {
	useContext,
	useCallback,
} from "react";
import "./TrackingChecker.scss";
import useFetchData from "../Shared/FetchData/FetchData";
import { getDisplayDate } from "../../utils/date.js";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import {
	Box,
	Grid,
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import { DataContext } from "../../App";

export default function TrackingChecker() {

	const context = useContext(DataContext);

	const apiData = useFetchData(useCallback(() =>
		context.dataProvider.getTrackingDetails(),
	[context.dataProvider]));

	const page_data = {
		tracking: {
			resultsData: apiData.results.sort((a, b) => a.bezos_order_date > b.bezos_order_date ? -1 : 1),
			resultsDataTotal: apiData.results.length,
			status: apiData.status,
			isLoading: apiData.isLoading,
		},
	};

	const PageHeading = () => {
		return (
			<Grid item xs={6} sm={8} lg={12} xl={12} container mb={3}>
				<Grid item xs="auto" mr={1} mb={2}>
					<Typography variant="h5">
						Tracking Checker
					</Typography>
				</Grid>
			</Grid>
		);
	};

	const TableData = () => {
		if (page_data.tracking.resultsData.length > 0) {
			return (
				page_data.tracking.resultsData.map((item, index) =>
					<TableRow key={index}>
						<TableCell>{item.bezos_order_number}</TableCell>
						<TableCell>{item.bezos_order_date !== "" ? getDisplayDate(item.bezos_order_date) : ""}</TableCell>
						<TableCell><StatusBox status={item.bezos_order_status} statusType="order" /></TableCell>
						<TableCell>{item.bezos_tracking_number}</TableCell>
						<TableCell>{item.warehouse_code}</TableCell>
						<TableCell>{item.warehouse_tracking_number}</TableCell>
						<TableCell>{item.seller_code}</TableCell>
					</TableRow>
				)
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan="7" className='table-error-message-text'>No results found</TableCell>
				</TableRow>
			);
		}
	};
	const TableHeader = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>
				<LoadableContent isLoading={page_data.tracking.isLoading}>
					<Typography variant="h5" sx={{ marginBottom: 4 }}>
						<RecordsTotal total={page_data.tracking.resultsDataTotal} />
					</Typography>
					<TableContainer sx={{ maxHeight: 600, overflow: "auto" }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell className="table-header">Bezos order number</TableCell>
									<TableCell className="table-header">Bezos order date</TableCell>
									<TableCell className="table-header">Bezos status</TableCell>
									<TableCell className="table-header">Bezos tracking number</TableCell>
									<TableCell className="table-header">Warehouse</TableCell>
									<TableCell className="table-header">Warehouse tracking number</TableCell>
									<TableCell className="table-header">Seller</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableData />
							</TableBody>
						</Table>
					</TableContainer>
				</LoadableContent>
			</Paper>);
	};

	return (
		<Box id="tracking-checker">
			<PageHeading />
			<TableHeader />
		</Box>
	);
}
