import React, {
	useState,
	useContext,
	useEffect,
} from "react";
import {
	Box,
	Grid,
	Paper,
	Typography,
	Button,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { setDocumentTitle } from "../../utils/page";
import { StatusBox } from "../Shared/StatusBox/StatusBox";
import { getDisplayDateTime } from "../../utils/date";
import { getEmojiAnswer } from "../../utils/emoji";
import { getFormattedCutoffTime } from "../../utils/time";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../App";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import { BreadCrumb } from "../Shared/BreadCrumb/BreadCrumb";

export default function WarehouseDetail() {

	const context = useContext(DataContext);
	const navigate = useNavigate();
	const { state: locationState } = useLocation();
	const warehouse = locationState.warehouse[0];

	const [state, setState] = useState({
		warehouseCredentialsData: null,
		isLoading: false,
	});

	const pageName = `Warehouse Details: ${warehouse.name}`;
	setDocumentTitle(pageName);

	useEffect(() => {
		const getWarehouseCredentials = async () => {

			setState(previousState => ({
				...previousState,
				isLoading: true,
			}));
	
			const params = new URLSearchParams({
				warehouse_id: warehouse.id,
			});
	
			try {
				const res = await context.dataProvider.getWarehouseCredentials(params);
				const data = res?.data || null;
	
				setState(previousState => ({
					...previousState,
					warehouseCredentialsData: data,
					isLoading: false,
				}));
			} catch (error) {
				setState(previousState => ({
					...previousState,
					warehouseCredentialsData: null,
					isLoading: false,
				}));
			}
		};
	
		getWarehouseCredentials();
	}, [context.dataProvider, warehouse.id]);

	const EditButton = () => {

		const editWarehouse = () => {
			navigate("/warehouse-update", { 
				state: { warehouse: locationState.warehouse } 
			});
		};

		return (
			<Button 
				title="Edit Warehouse"
				variant="outlined"
				onClick={() => editWarehouse()}
			>
				Edit Warehouse
			</Button>
		);
	};

	const PageHeading = () => (
		<Grid item xs={12}>
			<Box>
				<Grid container spacing={1} mt={1}>
					<Grid item xs={12} container spacing={3} mb={2}>
						<Grid item xs="auto">
							<Typography component="h1" variant="h5">
								{pageName} <StatusBox 
									status={warehouse.is_live? "true" : "false"} 
									statusType={"warehouse"}
									sx={{ ml: 1, position: "relative", bottom: "5px" }}
								/>
							</Typography>
						</Grid>
						<Grid item xs />
						<Grid item xs="auto">
							<Box>
								<EditButton />
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Grid>
	);

	const Summary = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", mb: 0 }} elevation={2}>
				<Typography component="h2" variant="h5" mb={1}>
					Summary
				</Typography>
				
				<Grid container spacing={1}>
					
					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>ID:</Box>
							<Box component="span">{warehouse.id}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Short Code:</Box>
							<Box component="span">{warehouse.short_code}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Platform ID:</Box>
							<Box component="span">{warehouse.platform_id}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Bezos System ID:</Box>
							<Box component="span">{warehouse.bezos_system_id}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Backward Sync Client ID:</Box>
							<Box component="span">{warehouse.backward_sync_client_id}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Location Code:</Box>
							<Box component="span">{warehouse.location_code}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Timezone:</Box>
							<Box component="span" sx={{ display: "block" }}>{warehouse.timezone}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Country:</Box>
							<Box component="span">{warehouse.country}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Currency:</Box>
							<Box component="span">{warehouse.currency}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Default Supplier:</Box>
							<Box component="span" sx={{ display: "block" }}>{warehouse.default_supplier}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Form URL:</Box>
							<Box component="span" sx={{ display: "block" }}>{warehouse.form_url}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Created At:</Box>
							<Box component="span">{getDisplayDateTime(warehouse.created_at)}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Minimum API Call Time (Min):</Box>
							<Box component="span">{warehouse.minimum_api_call_time_minutes}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>After Cutoff Time Despatch Email:</Box>
							<Box component="span">{getFormattedCutoffTime(warehouse.hours_after_cutoff_for_despatch_email, warehouse.minutes_after_cutoff_for_despatch_email)}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Cutoff Time:</Box>
							<Box component="span">{getFormattedCutoffTime(warehouse.cutoff_time_hour, warehouse.cutoff_time_minute)}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Default Client ID:</Box>
							<Box component="span">{warehouse.default_client_id ?? 0}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}></Box>
							<Box component="span"></Box>
						</Typography>
					</Grid>

				</Grid>
			</Paper>
		);
	};

	const AdditionalOptions = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", mb: 0 }} elevation={2}>
				<Typography component="h2" variant="h5" mb={1}>
                    Additional Options
				</Typography>
				
				<Grid container spacing={1}>
					
					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Sendcloud Brand Domain:</Box>
							<Box component="span">{warehouse.sendcloud_brand_domain}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Sendcloud Sender Address ID:</Box>
							<Box component="span">{warehouse.sendcloud_sender_address_id}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Is Backward Sync Enabled:</Box>
							<Box component="span">{getEmojiAnswer(warehouse.is_backward_sync_enabled)}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>ASN Cancellation Method:</Box>
							<Box component="span">{warehouse.asn_cancellation_method}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Uses Default ASN ID:</Box>
							<Box component="span">{getEmojiAnswer(warehouse.uses_default_asn_id)}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Always Use Default Supplier:</Box>
							<Box component="span">{getEmojiAnswer(warehouse.always_use_default_supplier)}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Supports Return Summary:</Box>
							<Box component="span">{getEmojiAnswer(warehouse.supports_return_summary)}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Has Collection Scan Delay:</Box>
							<Box component="span">{getEmojiAnswer(warehouse.has_collection_scan_delay)}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Has Event Based Despatch Sync:</Box>
							<Box component="span">{getEmojiAnswer(warehouse.has_event_based_despatch_sync)}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Requires Tracking Number For ASN:</Box>
							<Box component="span">{getEmojiAnswer(warehouse.requires_tracking_number_for_asn)}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Return Label Available:</Box>
							<Box component="span">{getEmojiAnswer(warehouse.return_label_available)}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Send Non Despatched Order Emails:</Box>
							<Box component="span">{getEmojiAnswer(warehouse.send_non_despatched_order_emails)}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Requires Contact Details For International Orders:</Box>
							<Box component="span">{getEmojiAnswer(warehouse.requires_contact_details_for_international_orders)}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Requires Contact Details For Domestic Orders:</Box>
							<Box component="span">{getEmojiAnswer(warehouse.requires_contact_details_for_domestic_orders)}</Box>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}></Box>
							<Box component="span"></Box>
						</Typography>
					</Grid>

				</Grid>
			</Paper>
		);
	};

	const ContactDetails = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
				<Typography component="h2" variant="h5" mb={1}>
                    Contact Details
				</Typography>
				
				<Grid container spacing={1}>
					
					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Contact Name:</Box>
							<Box component="span" sx={{ display: "block" }}>{warehouse.contact_name}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Contact Email:</Box>
							<Box component="span">{warehouse.contact_email}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6} mt={0.5}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Ops Support Contact:</Box>
							<Box component="span" sx={{ display: "block" }}>{warehouse.ops_support_contact}</Box>
						</Typography>
					</Grid>
	
					<Grid item xs={12} sm={12} md={6} xl={6}>
						<Typography component="div">
							<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>Zohodesk Contact:</Box>
							<Box component="span">{warehouse.zohodesk_contact}</Box>
						</Typography>
					</Grid>

				</Grid>
			</Paper>
		);
	};

	const WarehouseCredentials = () => {
		const credentialsData = state.warehouseCredentialsData
			? (Array.isArray(state.warehouseCredentialsData)
				? state.warehouseCredentialsData
				: [state.warehouseCredentialsData])
			: [];
	
		return (
			<Paper sx={{ p: 2, width: "100%", mb: 2 }} elevation={2}>
				<Typography component="h2" variant="h5" mb={1}>
					Credentials
				</Typography>
	
				{credentialsData.length > 0 ? (
					credentialsData.map((item, index) => (
						<Grid container spacing={1} key={index} sx={{ mb: 2 }}>
							{Object.entries(item).map(([key, value], idx) => (
								<Grid item xs={12} sm={12} md={6} xl={6} mt={idx % 2 === 0 ? 0.5 : 0} key={key + idx}>
									<Typography component="div">
										<Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>
											{key.replace(/_/g, " ").replace(/\b\w/g, l => l.toUpperCase()) + ":"}
										</Box>
										<Box component="span">
											{value}
										</Box>
									</Typography>
								</Grid>
							))}
						</Grid>
					))
				) : (
					<Grid item xs={12}>
						<Typography>No credentials found.</Typography>
					</Grid>
				)}
			</Paper>
		);
	};

	return (
		<Box id="warehouse-detail">
			<LoadableContent isLoading={state.isLoading} marginBottom={4}>
				<Grid container>
					<BreadCrumb 
						breadcrumbs={[
							{ label: "Warehouse Configuration", url: "/warehouse-configuration" },
							{ label: warehouse?.name }
						]}
					/>
					<PageHeading />
					<Grid container spacing={2} sx={{ display: "flex" }}>
						<Grid item xs={12} sm={12} md={6} xl={6} sx={{ display: "flex" }}>
							<Summary />
						</Grid>
						<Grid item xs={12} sm={12} md={6} xl={6} sx={{ display: "flex" }}>
							<Box sx={{ flex: 1 }}>
								<ContactDetails />
								<AdditionalOptions />
							</Box>
						</Grid>
						<Grid item xs={12}>
							<WarehouseCredentials />
						</Grid>
					</Grid>
				</Grid>
			</LoadableContent>
		</Box>
	);

}
