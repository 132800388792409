import React, {
	useState,
	useContext,
	useCallback,
	useMemo
} from "react";
import "./CourierChecker.scss";
import useFetchData from "../Shared/FetchData/FetchData";
import {
	EndDatePicker,
	StartDatePicker
} from "../Shared/DatePicker";
import {
	getCurrentDateTruncated,
	getFormattedStartTime,
	getFormattedEndTime
} from "../../utils/date.js";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";
import { RecordsTotal } from "../Shared/ResultsFound/ResultsFound";
import {
	Box,
	Grid,
	Paper,
	Table,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import Modal from "../Shared/Modal/ModalEmail";
import LightTooltip from "../Shared/LightTooltip";
import { DataContext } from "../../App";

export default function CourierChecker() {

	const context = useContext(DataContext);
	const initStartDate = getCurrentDateTruncated();
	const initEndDate = getCurrentDateTruncated();
	initStartDate.setDate(initStartDate.getDate() - 1);

	const [state, setState] = useState({
		startDate: initStartDate,
		endDate: initEndDate,
	});

	const params = useMemo(() => new URLSearchParams({
		startTime: getFormattedStartTime(state.startDate),
		endTime: getFormattedEndTime(state.endDate),
	}), [state.startDate, state.endDate]);

	const apiData = useFetchData(useCallback(() =>
		context.dataProvider.getCourierDetails(params),
	[context.dataProvider, params]));

	const page_data = {
		courier: {
			resultsData: apiData.results.sort((a, b) => a.bezos_order_number > b.bezos_order_number ? -1 : 1),
			resultsDataTotal: apiData.results.length,
			status: apiData.status,
			isLoading: apiData.isLoading,
		},
	};

	const onStartDateChange = (newValue) => {
		setState(previousState => {
			return {
				...previousState,
				startDate: newValue
			};
		});
	};

	const onEndDateChange = (newValue) => {
		setState((previousState) => {
			return {
				...previousState,
				endDate: newValue
			};
		});
	};

	const url = "/v1/courier/email?";
	const getParams = (item) => {
		const params = new URLSearchParams();
		params.append("order_number", item.bezos_order_number);
		params.append("bezos_courier_service", item.bezos_courier_service);
		params.append("warehouse_courier_service", item.warehouse_courier_service);
		return params.toString();
	};

	const PageHeading = () => {
		return (
			<Grid item xs={6} sm={8} lg={12} xl={12} container mb={3}>
				<Grid item xs="auto" mr={1} mb={2}>
					<Typography variant="h5">
						Courier Checker
					</Typography>
				</Grid>
				<Grid item xs />
				<Grid item xs="auto">
					<Grid item xs="auto">
						<StartDatePicker onChange={onStartDateChange} date={state.startDate} />
						<EndDatePicker onChange={onEndDateChange} date={state.endDate} />
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const TableData = () => {
		if (page_data.courier.resultsData.length > 0) {
			return (
				page_data.courier.resultsData.map((item, index) =>
					<TableRow key={index}>
						<TableCell>{item.bezos_order_number}</TableCell>
						<TableCell>{item.bezos_courier_service}</TableCell>
						<TableCell>{item.warehouse_code}</TableCell>
						<TableCell>{item.warehouse_order_number}</TableCell>
						<TableCell>{item.warehouse_courier_service}</TableCell>
						<TableCell>{item.seller_code}</TableCell>
						<TableCell>{item.warehouse_order_status}</TableCell>
						<TableCell>{item.bezos_order_status}</TableCell>
						<TableCell>
							<LightTooltip
								title={"An email has already been sent regarding the courier discrepancy for this order."}
								disableHoverListener={!item.email_sent}
								arrow
							>
								<Grid item xs="auto">
									<Modal
										buttonName={"send email"}
										buttonType={"outlined"}
										messageTitle={"Courier Discrepancy"}
										messageBody={`Please confirm you wish to send ${item.warehouse_code} an email for the courier discrepancy for order ${item.bezos_order_number}?`}
										url={url}
										params={getParams(item)}
										disabled={item.email_sent}
									/>
								</Grid>
							</LightTooltip>
						</TableCell>
					</TableRow>
				)
			);
		} else {
			return (
				<TableRow>
					<TableCell colSpan="9" className='table-error-message-text'>No results found</TableCell>
				</TableRow>
			);
		}
	};
	const TableHeader = () => {
		return (
			<Paper sx={{ p: 2, width: "100%", marginY: 2 }} elevation={2}>

				<LoadableContent isLoading={page_data.courier.isLoading}>

					<Typography variant="h5" sx={{ marginBottom: 4 }}>
						<RecordsTotal total={page_data.courier.resultsDataTotal} />
					</Typography>
					<TableContainer sx={{ maxHeight: 600, overflow: "auto" }}>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell className="table-header">Bezos order number</TableCell>
									<TableCell className="table-header">Bezos courier service</TableCell>
									<TableCell className="table-header">Warehouse</TableCell>
									<TableCell className="table-header">Warehouse order number</TableCell>
									<TableCell className="table-header">Warehouse courier service</TableCell>
									<TableCell className="table-header">Seller</TableCell>
									<TableCell className="table-header">Warehouse status</TableCell>
									<TableCell className="table-header">Bezos status</TableCell>
									<TableCell className="table-header"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableData />
							</TableBody>
						</Table>
					</TableContainer>
				</LoadableContent>
			</Paper>);
	};

	return (
		<Box id="courier-checker">
			<PageHeading />
			<TableHeader />
		</Box>
	);
}
